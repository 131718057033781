import axios from 'axios'
import * as types from '../mutation-types'
import { workAllocations } from '@/data/pageContents'
import { systemService } from '@/services'

// state
const state = {
  workAllocations: null,
  currentWorkAllocation: null
}

// getters
const getters = {
  workAllocations: state => state.workAllocations,
  getCurrentWorkAllocation: state => policyNumber => {
    return state.workAllocations.claimMasters.find(wa => wa.policyNumber === policyNumber)
  }
}

// mutations
const mutations = {
  [types.FETCH_WORKALLOCATIONS_SUCCESS](state, items) {
    state.workAllocations = items
  },
  [types.FETCH_WORKALLOCATIONS_FAILURE](state) {
    state.workAllocations = []
  },
  [types.UPDATE_WORKALLOCATIONS_SUCCESS](state, payload) {
    const item = state.workAllocations.claimMasters.find(item => item.id === payload.id)
    Object.assign(item, payload)
  },
  [types.UPDATE_WORKALLOCATIONS_FAILURE](state) {
    state.workAllocations = []
  },
  [types.RESET_WORKALLOCATIONS_SUCCESS](state, items) {
    state.workAllocations = items
  },
  [types.RESET_WORKALLOCATIONS_FAILURE](state) {
    state.workAllocations = []
  },
  [types.SAVE_CURRENT_WORKALLOCATION_SUCCESS](state, item) {
    state.currentWorkAllocation = item
  },
  [types.SAVE_CURRENT_WORKALLOCATION_FAILURE](state) {
    state.currentWorkAllocation = null
  },
  [types.COMPLETE_WORKALLOCATION_SUCCESS](state, payload) {
    let item = state.workAllocations.claimMasters.find(claim => claim.id === payload)
    item.completed = true
  }
}

// actions
const actions = {
  async fetchWorkAllocations({ commit, dispatch }, weekId) {
    try {
      const { data } = await systemService.getWorkAllocations(weekId)
      await commit(types.FETCH_WORKALLOCATIONS_SUCCESS, data.data[0])
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Error occurs when fetching work allocations',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateWorkAlloction({ commit }, workAllocation) {
    try {
      await commit(types.UPDATE_WORKALLOCATIONS_SUCCESS, workAllocation)
    } catch (e) {
      console.log(e)
      // await commit(types.UPDATE_WORKALLOCATIONS_FAILURE)
    }
  },
  async resetWorkAllocations({ commit }, workAllocations) {
    try {
      await commit(types.RESET_WORKALLOCATIONS_SUCCESS, workAllocations)
    } catch (e) {
      await commit(types.RESET_WORKALLOCATIONS_FAILURE)
    }
  },
  async saveCurrentWorkAllocation({ commit }, workAllocation) {
    try {
      await commit(types.SAVE_CURRENT_WORKALLOCATION_SUCCESS, workAllocation)
    } catch (e) {
      await commit(types.SAVE_CURRENT_WORKALLOCATION_FAILURE)
    }
  },
  async completeWorkAllocation({ commit, dispatch }, claimId) {
    try {
      await commit(types.COMPLETE_WORKALLOCATION_SUCCESS, claimId)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to update',
        message: 'Error occurs when updating this work allocation'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
