import * as types from '../mutation-types'
import axios from 'axios'
import { systemService } from '@/services'

// state
const state = {
  claims: [],
  game: null,
  tempClaim: null
}

// getters
const getters = {
  claims: state => state.claims,
  game: state => state.game,
  tempClaim: state => state.tempClaim
}

// mutations
const mutations = {
  [types.FETCH_CLAIMS_SUCCESS](state, items) {
    state.claims = items
  },
  [types.FETCH_CLAIMS_FAILURE](state) {
    state.claims = []
  },
  [types.SAVE_CLAIM_SUCCESS](state, item) {
    state.claims.push(item)
  },
  [types.SAVE_CLAIM_FAILURE](state) {
    state.claims = []
  },
  [types.FETCH_GAME_SUCCESS](state, payload) {
    state.game = payload
  },
  [types.UPDATE_TEMPCLAIM](state, payload) {
    state.tempClaim = payload
  },
  [types.RESET_TEMPCLAIM](state, payload) {
    state.tempClaim = null
  }
}

// actions
const actions = {
  async fetchClaims({ commit }, token) {
    try {
      await commit(types.FETCH_CLAIMS_SUCCESS, claims)
    } catch (e) {
      await commit(types.FETCH_CLAIMS_FAILURE)
    }
  },
  async submitClaim({ commit, dispatch }, newClaim) {
    try {
      const { data } = await systemService.addClaim(newClaim)
      await commit(types.SAVE_CLAIM_SUCCESS, newClaim)
      const notification = {
        type: 'success',
        title: `Claim submitted successfully`,
        message: `Policy #${newClaim.policyNumber} has been submitted`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      await commit(types.SAVE_CLAIM_FAILURE)
      const notification = {
        type: 'error',
        title: `Error occurs when submitting this claim`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchGame({ commit, dispatch }, teamWeekDayId) {
    try {
      const { data } = await systemService.getGame(teamWeekDayId)
      await commit(types.FETCH_GAME_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching game`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateTempClaim({ commit }, claim) {
    await commit(types.UPDATE_TEMPCLAIM, claim)
  },
  async resetTempClaim({ commit }) {
    await commit(types.RESET_TEMPCLAIM)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
