import {
  WHATWENTWELL,
  COMMUNICATIONS,
  FEEDBACK,
  PROBLEMS,
  ACTIONS,
  STATUS,
  WEEKLYDATA,
  YESTERDAYDATA,
  PEOPLE
} from '@/data/dashboardContents'
import axios from 'axios'
import * as types from '../mutation-types'
import { dashboardService } from '@/services'

const state = {
  vmbData: null,
  vmbObservationData: null,
  status: null,
  weeklyData: null,
  yesterdayData: null,
  newVmbData: null
}

const getters = {
  vmbData: (state) => state.vmbData,
  vmbObservationData: (state) => state.vmbObservationData,
  status: (state) => state.status,
  weeklyData: (state) => state.weeklyData,
  yesterdayData: (state) => state.yesterdayData,
  newVmbData: (state) => state.newVmbData
}

const mutations = {
  [types.FETCH_VMBDATA_SUCCESS](state, payload) {
    state.vmbData = payload
  },
  [types.FETCH_VMBDATA_FAILURE](state) {
    state.vmbData = null
  },
  [types.FETCH_VMBOBSERVATIONDATA_SUCCESS](state, payload) {
    state.vmbObservationData = payload
  },
  [types.FETCH_VMBOBSERVATIONDATA_FAILURE](state, payload) {
    state.vmbObservationData = null
  },
  [types.UPDATE_VMBOBSERVATIONSTATUS_SUCCESS](state, payload) {
    let observationData = null
    let itemType = payload.type
    switch (itemType) {
      case 1:
        observationData = state.vmbObservationData.problems
        break
      case 2:
        observationData = state.vmbObservationData.actions
        break
      case 3:
        observationData = state.vmbObservationData.what_went_well
        break
      case 4:
        observationData = state.vmbObservationData.communication
        break
      case 5:
        observationData = state.vmbObservationData.feedback
        break
    }

    let observationItem = observationData.find((item) => item.id === payload.id)
    observationItem.status = payload.status
  },
  [types.UPDATE_VMBOBSERVATIONSTATUS_FAILURE](state) {},
  [types.SAVE_VMBITEMS_SUCCESS](state, payload) {
    state.newVmbData = payload
  },
  [types.SAVE_VMBITEMS_FAILURE](state) {
    state.newVmbData = null
  },
  [types.FETCH_VMBWEEKLYDATA_SUCCESS](state, payload) {
    state.weeklyData = payload
  },
  [types.FETCH_VMBWEEKLYDATA_FAILURE](state) {
    state.weeklyData = null
  },
  [types.FETCH_VMBYESTERDAYDATA_SUCCESS](state, payload) {
    state.yesterdayData = payload
  },
  [types.FETCH_VMBYESTERDAYDATA_FAILURE](state) {
    state.yesterdayData = null
  },
  [types.FETCH_VMBSTATUS_SUCCESS](state, payload) {
    state.status = payload
  },
  [types.FETCH_VMBSTATUS_FAILURE](state) {
    state.status = null
  },
  saveStatus(state, status) {
    state.status = status
  },
  saveWeeklyData(state, weeklyData) {
    state.weeklyData = weeklyData
  },
  saveYesterdayData(state, yesterdayData) {
    state.yesterdayData = yesterdayData
  },
  addDashboardItems(state, newItems) {
    if (newItems.whatWentWell.title.length > 0) {
      const newWwwId = Math.max(...state.allWhatWentWell.map((item) => item.id)) + 1
      state.allWhatWentWell.splice(0, 0, { id: newWwwId, ...newItems.whatWentWell })
      state.whatWentWell = state.allWhatWentWell
    }

    if (newItems.communications.title.length > 0) {
      const newCommId = Math.max(...state.allCommunications.map((item) => item.id)) + 1
      state.allCommunications.splice(0, 0, { id: newCommId, ...newItems.communications })
      state.communications = state.allCommunications
    }

    if (newItems.feedback.title.length > 0) {
      const newFbId = Math.max(...state.allFeedback.map((item) => item.id)) + 1
      state.allFeedback.splice(0, 0, { id: newFbId, ...newItems.feedback })
      state.feedback = state.allFeedback
    }

    if (newItems.problems.title.length > 0) {
      const newProbId = Math.max(...state.allProblems.map((item) => item.id)) + 1
      state.allProblems.splice(0, 0, { id: newProbId, ...newItems.problems })
      state.problems = state.allProblems
    }

    if (newItems.actions.title.length > 0) {
      const newActId = Math.max(...state.allActions.map((item) => item.id)) + 1
      state.allActions.splice(0, 0, { id: newActId, ...newItems.actions })
      state.actions = state.allActions
    }
  }
}

const actions = {
  async fetchVmbAllData({ commit, dispatch }) {
    try {
      const { data } = await dashboardService.getVmbData()
      await commit(types.FETCH_VMBDATA_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching VMB data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchVmbObservationData({ commit, dispatch }) {
    try {
      const { data } = await dashboardService.getVmbObservationData()
      await commit(types.FETCH_VMBOBSERVATIONDATA_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching VMB observation data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async submitVmbItems({ commit, dispatch }, newVmbItems) {
    try {
      const { data } = await dashboardService.addVmbItem(newVmbItems)
      await commit(types.SAVE_VMBITEMS_SUCCESS, newVmbItems)
      const notification = {
        type: 'success',
        title: `VMB sumbmitted`,
        message: 'VMB has been successfully submitted'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when submitting VMB survey`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
      await commit(types.SAVE_VMBITEMS_FAILURE)
    }
  },
  async updateVmbObservationStatus({ commit }, payload) {
    try {
      const { data } = await dashboardService.updateVmbObservationStatus(payload)
      await commit(types.UPDATE_VMBOBSERVATIONSTATUS_SUCCESS, payload)
      return { status: 'success', message: data.message }
    } catch (e) {
      console.log(e)
      await commit(types.UPDATE_VMBOBSERVATIONSTATUS_FAILURE)
      return { status: 'error', message: e.response.data.message }
    }
  },
  async fetchVmbWeeklyData({ commit, dispatch }, payload) {
    try {
      const { data } = await dashboardService.getVmbWeeklyData()
      await commit(types.FETCH_VMBWEEKLYDATA_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching weekly data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchVmbYesterdayData({ commit, dispatch }, weekId) {
    try {
      const { data } = await dashboardService.getVmbYesterdayData(weekId)
      await commit(types.FETCH_VMBYESTERDAYDATA_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching yesterday data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchVmbStatus({ commit }) {
    await commit(types.FETCH_VMBSTATUS_SUCCESS, STATUS)
  },
  getStatus({ commit }) {
    let status = STATUS
    commit('saveStatus', status)
  },
  getWeeklyData({ commit }) {
    let weeklyData = WEEKLYDATA
    commit('saveWeeklyData', weeklyData)
  },
  getYesterdayData({ commit }) {
    let yesterdayData = YESTERDAYDATA
    commit('saveYesterdayData', yesterdayData)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
