var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "running-loader" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm.loading
      ? _c("div", [_c("span", [_vm._v(_vm._s(_vm.loadingText))])])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "running d-flex justify-content-center" }, [
      _c("div", { staticClass: "outer" }, [
        _c("div", { staticClass: "body" }, [
          _c("div", { staticClass: "arm behind" }),
          _vm._v(" "),
          _c("div", { staticClass: "arm front" }),
          _vm._v(" "),
          _c("div", { staticClass: "leg behind" }),
          _vm._v(" "),
          _c("div", { staticClass: "leg front" }),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }