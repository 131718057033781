import i18n from '@/i18n'

export const homePage = [
  {
    key: 1,
    title: i18n.t('pages.home.rules.title'),
    subtitle: i18n.t('pages.home.rules.content'),
    thumb: '/assets/img/svg/achievement_outline.svg',
    link: '/rules'
  },
  {
    key: 2,
    title: i18n.t('pages.home.policies.title'),
    subtitle: i18n.t('pages.home.policies.content'),
    thumb: '/assets/img/svg/note_outline.svg',
    link: '/policies'
  },
  {
    key: 3,
    title: i18n.t('pages.home.systems.title'),
    subtitle: i18n.t('pages.home.systems.content'),
    thumb: '/assets/img/svg/startup_outline.svg',
    link: '/lodgements'
  }
]

export let rulesPage = [
  {
    id: 1,
    title: i18n.t('pages.rules.business.title'),
    subtitle: '',
    img: '/assets/img/svg/rules/data-analyzing-outline.svg',
    rules: [
      i18n.t('pages.rules.business.rule1'),
      i18n.t('pages.rules.business.rule2'),
      i18n.t('pages.rules.business.rule3'),
      i18n.t('pages.rules.business.rule4'),
      i18n.t('pages.rules.business.rule5'),
      i18n.t('pages.rules.business.rule6'),
      i18n.t('pages.rules.business.rule7')
    ]
  },
  {
    id: 2,
    title: i18n.t('pages.rules.scoring.title'),
    subtitle: '',
    img: '/assets/img/svg/rules/start-up-outline.svg',
    rules: [
      i18n.t('pages.rules.scoring.rule1'),
      i18n.t('pages.rules.scoring.rule2'),
      i18n.t('pages.rules.scoring.rule3'),
      i18n.t('pages.rules.scoring.rule4')
    ]
  },
  {
    id: 3,
    title: i18n.t('pages.rules.lodging.title'),
    subtitle: '',
    img: '/assets/img/svg/rules/calculator-outline.svg',
    rules: [
      i18n.t('pages.rules.lodging.rule1'),
      i18n.t('pages.rules.lodging.rule2'),
      i18n.t('pages.rules.lodging.rule3'),
      i18n.t('pages.rules.lodging.rule4'),
      i18n.t('pages.rules.lodging.rule5'),
      i18n.t('pages.rules.lodging.rule6')
    ]
  }
]

export const policiesPage = [
  {
    id: 1,
    title: i18n.t('pages.policies.rent.title'),
    subtitle: i18n.t('pages.policies.rent.subtitle'),
    overview: i18n.t('pages.policies.rent.overview'),
    covered: i18n.t('pages.policies.rent.covered'),
    img: '/assets/img/svg/policies/real-estate-agent-outline.svg',
    items: ['jewellery', 'art piece']
  },
  {
    id: 2,
    title: i18n.t('pages.policies.home.title'),
    subtitle: i18n.t('pages.policies.home.subtitle'),
    overview: i18n.t('pages.policies.home.overview'),
    covered: i18n.t('pages.policies.home.covered'),
    img: '/assets/img/svg/policies/finance-app-outline.svg',
    items: ['jewellery', 'art piece']
  },
  {
    id: 3,
    title: i18n.t('pages.policies.car.title'),
    subtitle: i18n.t('pages.policies.car.subtitle'),
    overview: i18n.t('pages.policies.car.overview'),
    covered: i18n.t('pages.policies.car.covered'),
    img: '/assets/img/svg/policies/road-trip-outline.svg',
    items: ['car']
  }
]

export const systemsPage = [
  {
    id: 1,
    title: 'Week 1',
    status: 'complete',
    img: '/assets/img/svg/team_presentation1.svg'
  },
  {
    id: 2,
    title: 'Week 2',
    status: 'complete',
    img: '/assets/img/svg/team_presentation2.svg'
  },
  {
    id: 3,
    title: 'Week 3',
    status: 'open',
    img: '/assets/img/svg/team_presentation3.svg'
  },
  {
    id: 4,
    title: 'Week 4',
    status: 'waiting',
    img: '/assets/img/svg/team_presentation4.svg'
  }
]

export const workAllocations = {
  id: '1',
  userId: '111',
  teamWeekId: '211',
  workVolume: '4',
  workComplexity: 2,
  claimMastersFields: [
    'completed',
    'givenName',
    'surname',
    'policyNumber',
    'policyType',
    'policyName',
    'claimedType',
    'claimDay',
    'claimAmount',
    'excessApplicable'
  ],
  claimMasters: [
    {
      id: '27336',
      givenName: 'Kai',
      surname: 'Ashburn',
      policyNumber: 'HPA311293801',
      policyType: 'Car',
      policyName: 'Car Protect',
      claimedType: 'Accident',
      claimDay: 'Monday',
      claimAmount: '10000',
      excessApplicable: '800',
      completed: false
    },
    {
      id: '27337',
      givenName: 'Kai',
      surname: 'Ashburn',
      policyNumber: 'HPA311293802',
      policyType: 'Car',
      policyName: 'Car Protect',
      claimedType: 'Accident',
      claimDay: 'Monday',
      claimAmount: '15000',
      excessApplicable: '1500',
      completed: false
    },
    {
      id: '27338',
      givenName: 'Kai',
      surname: 'Ashburn',
      policyNumber: 'HPA311293803',
      policyType: 'Car',
      policyName: 'Car Protect',
      claimedType: 'Accident',
      claimDay: 'Monday',
      claimAmount: '17000',
      excessApplicable: '2000',
      completed: false
    },
    {
      id: '27339',
      givenName: 'Kai',
      surname: 'Ashburn',
      policyNumber: 'HPA311293804',
      policyType: 'Car',
      policyName: 'Car Protect',
      claimedType: 'Accident',
      claimDay: 'Monday',
      claimAmount: '17125',
      excessApplicable: '500',
      completed: false
    },
    {
      id: '27340',
      givenName: 'Kai',
      surname: 'Ashburn',
      policyNumber: 'HPA311293804',
      policyType: 'Car',
      policyName: 'Car Protect',
      claimedType: 'Accident',
      claimDay: 'Monday',
      claimAmount: '18000',
      excessApplicable: '500',
      completed: false
    },
    {
      id: '27341',
      givenName: 'Kai',
      surname: 'Ashburn',
      policyNumber: 'HPA311293804',
      policyType: 'Car',
      policyName: 'Car Protect',
      claimedType: 'Accident',
      claimDay: 'Monday',
      claimAmount: '500',
      excessApplicable: '200',
      completed: false
    }
  ]
}

export const termsOfUse = [
  {
    title: 'General terms',
    section: [
      {
        section:
          "In these Terms of Use, “PwC”, “we”, “us” and “our” means PricewaterhouseCoopers Consulting (Australia) Pty Ltd (ABN 20 607 773 295).  “You”, “your” and similar expressions refers to the individual, corporate, trust, partnership or other entity (and any of their related bodies corporate) agreeing to use the Perform Bootcamp Simulation application available at <a href='https://www.perform.pwc.com' target='_blank'>www.perform.pwc.com</a> (“<b>Application</b>”) for the purpose of registering to attend or use any workshop, live or recorded webinar, or other e-Learning course or activity or material offered through the Application (individually or together “Training”)."
      },
      {
        section:
          'By accessing or using the Application, you represent that you are authorised to enter into these Terms of Use for the Application and the Training and you agree that you will comply with these terms and all applicable laws and regulations.'
      },
      {
        section:
          'As part of your use of the Application, you may permit your employees, representatives and any person authorised by you (“Invited Users”) to register for or participate in Training, provided that you acknowledge that we may refuse, cancel, or suspend, rights for any person to access or use the Application or Training for any reason in our sole and absolute discretion. You must ensure that your Invited Users comply with these Terms of Use. You will be liable for the acts and omissions of your Invited Users as if they were your acts or omissions.'
      },
      {
        section:
          'Where the context permits, a reference to “you” and “your” in these Terms of Use also refers to each of your Invited Users.'
      }
    ]
  },
  {
    title: 'Your use of the Application and Training',
    section: [
      {
        section:
          'Unless you enter a separate agreement in writing with PwC, in consideration for the performance of your obligations, the following conditions apply in relation to any rights we grant to you for a time-limited, non-transferable, non-exclusive licence to access and use the Application and/or any of the Training and/or your rights to attend workshop(s) and/or live webinar(s) for your own learning and development needs within Australia:',
        subSection: [
          {
            section:
              'You agree to access and use the Application and any Training you register for or receive in a manner consistent with their intended purposes.'
          },
          {
            section:
              'You acknowledge that we may from time to time and without notice, vary, modify, or terminate, temporarily or permanently your access or use of the Application and/or Training and/or these Terms of Use at our sole discretion.'
          },
          {
            section:
              'We (or our licensors) retain all rights, title, and interests in and to the Application and Training, and nothing you do on or in relation to the Application and Training will transfer any intellectual property rights to you unless expressly stated.'
          },
          {
            section:
              'We (or our licensors) retain all rights, title, and interests in and to the Application and Training, and nothing you do on or in relation to the Application and Training will transfer any intellectual property rights to you unless expressly stated.'
          },
          {
            section:
              'If we have told you that you may transfer a registration for a workshop or other activity session you are attending, you must notify PwC by sending an email to the email address we have given you for this purpose to tell us you wish to transfer the registration to another person prior to the scheduled session.'
          },
          {
            section:
              'Any self-paced webinar or e-learning offerings are provided on a  use-it-or-lose-it basis and are not transferable.'
          },
          {
            section:
              'If PwC cancels a workshop or other activity session, for any reason, PwC may schedule another session to which you may be invited.'
          },
          {
            section:
              'You, including any Invited User(s), will be required to register an individual email address and password (or other log in details) via the Application for each workshop or other activity session prior to attending the relevant  workshop or activity session or receiving rights to access a webinar or e-Learning via the Application.'
          },
          {
            section:
              'You agree that you have sole responsibility for the accuracy and protection of the information that you or any other person input into the Application (including any personal information) and that in no event shall we or our beneficiaries be liable for any loss of any kind (including loss or corruption of your data) arising from any act or omission.'
          },
          {
            section:
              'You must keep your log in details secure and confidential and not disclose or allow anybody else to use those log in details. We may assume that any person accessing or using the Application using those log in details is you.'
          },
          {
            section:
              "Our approach to privacy is set out in PwC’s Privacy Policy at <a href='https://www.pwc.com.au/contact-us/privacy-policy.html' target='_blank'>https://www.pwc.com.au/contact-us/privacy-policy.html</a> We agree to cooperate with each other to address our respective obligations to comply with the privacy and other data protection legislation applicable to any personal information  shared in connection with the registration process and/or any other interaction with PwC for use of the Application and Training."
          },
          {
            section:
              'You must not seek to circumvent access or security controls or otherwise threaten the security of the Application.'
          },
          {
            section:
              "You must notify us immediately by email to <a href='mailto:au_privacy_officer_mbx@pwc.com'>au_privacy_officer_mbx@pwc.com</a> if you become aware of, or have reason to suspect that there has been any unauthorised access to or use of the Application using your log in details."
          },
          {
            section:
              'We may suspend your access to the Application where we reasonably believe there has been any unauthorised access to, or use of, the Application through your log in details. Where we do so, we will notify you within a reasonable time of the suspension occurring, and the parties will work together to resolve the matter.'
          },
          {
            section:
              'If you experience any error messages, anomalies, defects, glitches and/or  other issues affecting your use of the Application, then you will promptly notify us by sending an email to the email address we have given you for this purpose and as part of this notification you will provide a screenshot of the error and include it in your email notification to us. We will attempt to resolve any matter as soon as we reasonably can and endeavour to respond to any queries within 1 business day.'
          },
          {
            section:
              'Except as provided in these Terms of Use, any use or copying of the Application or Training for any other purpose is expressly prohibited, unless prior written consent is obtained from us.'
          },
          {
            section:
              'You hereby indemnify PwC and undertake to keep PwC indemnified against any losses, damages, costs, liabilities and expenses (including legal expenses) arising out of:',
            subSection: [
              {
                section: 'your use of and access to the Application and any Training;'
              },
              {
                section:
                  'any breach by you of any provision of these Terms of Use or arising out of any claim that you have breached any provision of these Terms of Use;'
              },
              {
                section:
                  'your breach of any third party right, including without limitation, any intellectual property, confidentiality or privacy right; and'
              },
              {
                section: 'any claim that you caused damage to a third party.'
              }
            ]
          },
          {
            section: 'In using the Application or Training, you must not:',
            subSection: [
              {
                section: 'copy, decompile or reverse engineer the Application or Training;'
              },
              {
                section: 'provide us with inaccurate or incomplete information;'
              },
              {
                section:
                  'violate any applicable laws or use Application or Training for any purpose that is unlawful;'
              },
              {
                section:
                  'distribute viruses, spyware, corrupted files, or any other similar software or programs that may damage the operation of any computer hardware or software; or'
              },
              {
                section:
                  'engage in any other conduct that inhibits any other person from using or enjoying the Application or Training.'
              }
            ]
          },
          {
            section:
              'You acknowledge that it is your responsibility to implement sufficient procedures and virus checks (including anti-virus and other security checks).'
          }
        ]
      }
    ]
  },
  {
    title: 'Our rights and obligations',
    section: [
      {
        section:
          'Any amendments to these Terms of Use will apply from the date of the publication on the Application.  You agree to be bound by any amendments to these Terms of Use if you continue to access or use the Application or Training after the amendments are made.'
      },
      {
        section:
          'We provide access to the Application and all its content, materials and information (including any Training) on an “as is” and “as available” basis. We reserve the right to make changes or updates to the Application and Training at any time without notice and we make no warranty that the Application or Training will be available on an uninterrupted, timely, or error-free basis. Your access to the service may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. We will attempt to restore the service as soon as we reasonably can.'
      },
      {
        section:
          'PwC may engage third parties, including but not limited to other member firms of the PricewaterhouseCoopers global network of firms (“<b>PwC Member Firms</b>”, each of which is a separate and independent legal entity), and third party providers of cloud-based data hosting, located in Australia and overseas, to assist us in providing the Application and Training to you. You agree that PwC may transfer information provided to us by you to those third parties, provided they are bound by confidentiality obligations.'
      },
      {
        section:
          'Although we aim to offer you a high quality experience, we make no promise that your experience will meet your particular learning and development requirements. We make no warranty or other obligation that successful knowledge transfer to/application by individuals will take place.'
      },
      {
        section:
          'PwC accepts no responsibility for any person who acts or relies in any way on any of the Application or Training without first obtaining specific advice.'
      },
      {
        section:
          'We take no responsibility for matters arising from changed circumstances or other information or material, which may affect the accuracy or currency of the Application or any Training.'
      },
      {
        section:
          'To the extent permitted by law, we exclude all liability to you in respect of this Application and any Training.  To the extent that such exclusion is not effective,  PwC’s maximum aggregate liability to you for all claims for direct loss or damages suffered by you under or relating to these Terms of Use is limited to the amount you have paid to access or use this Application or any Training.  PwC is not liable for any indirect or consequential loss or loss of profit.'
      },
      {
        section:
          'You undertake not to bring any claims in respect of the Application or any Training against any other PwC Member Firm (or any of their partners, officers, employees, or members) or third party supplier to PwC.'
      },
      {
        section:
          'PwC will use reasonable care to ensure that the details published on the Application  and in any Training are correct at the time of publication.  However, we make no promise that the Application and all its content, materials and information (including any Training) are appropriate or available for use in locations outside Australia. If you choose to access the Application from a location outside Australia, you do so on your own initiative and are responsible for compliance with local laws.'
      },
      {
        section:
          'If any part of these Terms of Use is held to be unenforceable, the unenforceable part is to be given effect to the greatest extent possible and the remainder will remain in full force and effect.'
      },
      {
        section:
          'These Terms of Use are governed by the laws of the State of New South Wales, Australia and both of us submit to the jurisdiction of the courts of that State and waive any right either of us may have to claim that those courts do not have jurisdiction or are an inconvenient forum.'
      },
      {
        section:
          'These terms of use constitute the entire agreement between us and you in relation to the Application and Training and supersede all other (prior or contemporaneous) communications or displays whether electronic, oral, or written, between us.'
      }
    ]
  }
]

export const termsOfUseUK = [
  {
    section:
      'While we have made every attempt to ensure that the information contained in this Site has been obtained from reliable sources, PricewaterhouseCoopers LLP is not responsible for any errors or omissions, or for the results obtained from the use of this information. All information in this Site is provided “as is”, with no guarantee of completeness, accuracy, timeliness or of the results obtained from the use of this information, and without warranty of any kind, express or implied, including, but not limited to warranties of performance, merchantability and fitness for a particular purpose. Nothing herein shall to any extent substitute for the independent investigations and the sound technical and business judgment of the reader. In no event will PricewaterhouseCoopers LLP, or its partners, employees or agents, be liable to you or anyone else for any decision made or action taken in reliance on the information in this Site or for any consequential, special or similar damages, even if advised of the possibility of such damages.'
  },
  {
    section:
      'Certain links in this Site connect to other Web Sites maintained by third parties over whom PricewaterhouseCoopers LLP has no control. PricewaterhouseCoopers LLP makes no representations as to the accuracy or any other aspect of information contained in other Web Sites.'
  },
  {
    section:
      'PricewaterhouseCoopers LLP is a limited liability partnership registered in England with registered number OC303525. The registered office of PricewaterhouseCoopers LLP is 1 Embankment Place, London WC2N 6RH, United Kingdom.'
  },
  {
    section:
      'Unless otherwise indicated either expressly or by the context, we use the word “partner” to describe a member of PricewaterhouseCoopers LLP in their capacity as such.'
  },
  {
    section:
      'The information contained in this Site is intended solely to provide general guidance on matters of interest for the personal use of the reader, who accepts full responsibility for its use. The application and impact of laws can vary widely based on the specific facts involved. Given the changing nature of laws, rules and regulations, and the inherent hazards of electronic communication, there may be delays, omissions or inaccuracies in information contained in this Site. Accordingly, the information on this Site is provided with the understanding that the authors and publishers are not herein engaged in rendering legal, accounting, tax, or other professional advice or services. As such, it should not be used as a substitute for consultation with professional accounting, tax, legal or other competent advisers. Before making any decision or taking any action, you should consult a PricewaterhouseCoopers professional.'
  }
]
