import * as types from '../mutation-types'
import app from '@/main'
import { adminService } from '@/services'
import { setChartColor, setTableHeaderColor } from '@/utils/chart-helper'
import { setCurrentLanguage } from '@/utils'
import { defaultLanguage, formatPayment } from '@/constants/config'

const state = {
  allTeamWeekDays: null,
  appTitle: null,
  auditLog: [],
  auditLogFilters: null,
  currency: null,
  gameFinancials: [],
  language: defaultLanguage,
  locale: null,
  overallAccuracy: null,
  overallProfitability: null,
  overallVolume: null,
  roundBreakdown: null,
  teams: null,
  teamApprovalCodeValidation: null,
  teamMaster: null,
  teamProcessedScores: null,
  teamWeekDays: null,
  teamWeeks: null,
  teamWeekWorkAllocations: null,
  teamWeekWorkAllocationsStatus: null,
  teamWorkAllocations: null,
  teamWorkComplexity: null,
  triggeredGame: null,
  users: null,
  weeklyAccuracy: null,
  weeklyProfitability: null,
  weeklyVolume: null
}

const getters = {
  allTeams: (state) => state.teams,
  allTeamWeeks: (state) => state.teamWeeks,
  currentLanguage: (state) => state.language,
  teamWeekDays: (state) => state.teamWeekDays,
  allTeamWeekDays: (state) => state.allTeamWeekDays,
  allTeamWorkAllocations: (state) => state.teamWorkAllocations,
  allTeamWeekWorkAllocations: (state) => state.teamWeekWorkAllocations,
  teamWeekWorkAllocationsStatus: (state) => state.teamWeekWorkAllocationsStatus,
  teamWorkComplexity: (state) => state.teamWorkComplexity,
  teamApprovalCodeValidation: (state) => state.teamApprovalCodeValidation,
  allUsers: (state) => state.users,
  allTeamMaster: (state) => state.teamMaster,
  getTeamWeekDaysByTeam: (state) => (index) => {
    return state.teamWeekDays.find((twd) => twd.teamId === index)
  },
  getDefaultTeamId: (state) => {
    return state.teams[0].id
  },
  getWorkAllocationsByTeam: (state) => (index) => {
    return state.teamWorkAllocations.find((twd) => twd.teamId === index)
  },
  allTeamProcessedScores: (state) => state.teamProcessedScores,
  overallProfitability: (state) => state.overallProfitability,
  overallVolume: (state) => state.overallVolume,
  overallAccuracy: (state) => state.overallAccuracy,
  weeklyProfitability: (state) => state.weeklyProfitability,
  weeklyVolume: (state) => state.weeklyVolume,
  weeklyAccuracy: (state) => state.weeklyAccuracy,
  roundBreakdown: (state) => state.roundBreakdown,
  triggeredGame: (state) => state.triggeredGame,
  appTitle: (state) => state.appTitle,
  locale: (state) => state.locale,
  currency: (state) => state.currency,
  gameFinancials: (state) => state.gameFinancials,
  auditLog: (state) => state.auditLog,
  auditLogFilters: (state) => state.auditLogFilters
}

const mutations = {
  [types.FETCH_TEAMS_SUCCESS](state, items) {
    state.teams = items
  },
  [types.FETCH_TEAMS_FAILURE](state) {
    state.teams = null
  },
  [types.FETCH_ALLTEAMS_SUCCESS](state, items) {
    state.allTeams = items
  },
  [types.FETCH_TEAMWEEKS_SUCCESS](state, items) {
    state.teamWeeks = items
  },
  [types.FETCH_TEAMWEEKDAYS_SUCCESS](state, items) {
    state.teamWeekDays = items
  },
  [types.FETCH_TEAMWEEKDAYS_FAILURE](state) {
    state.teamWeekDays = null
  },
  [types.FETCH_ALLTEAMWEEKDAYS_SUCCESS](state, payload) {
    state.allTeamWeekDays = payload
  },
  [types.SUBMIT_NEXTWEEKDAY_SUCCESS](state, payload) {
    let teamWeekId = payload.teamWeekId
    let pastWeekDayId = payload.pastWeekDayId
    let nextWeekDayId = payload.nextWeekDayId
    let teamWeek = state.allTeamWeekDays.team_weeks.find((tw) => tw.id === teamWeekId)
    let pastWeekDay = teamWeek.team_week_days.find((twd) => twd.id === pastWeekDayId)
    let nextWeekDay = teamWeek.team_week_days.find((twd) => twd.id === nextWeekDayId)
    pastWeekDay.status_id = 4
    pastWeekDay.rollback = true
    pastWeekDay.current_day = false
    nextWeekDay.status_id = 3
    nextWeekDay.rollback = false
    nextWeekDay.current_day = true
  },
  [types.SUBMIT_NEXTWEEKDAY_FAILURE](state) {},
  [types.SUBMIT_NEXTWEEK_SUCCESS](state, payload) {
    let teamWeekId = payload
    let allTeamWeeks = state.allTeamWeekDays.team_weeks
    let teamWeek = allTeamWeeks.find((tw) => tw.id === teamWeekId)
    // complete all weekdays
    teamWeek.team_week_days.map((twd) => {
      twd.status_id = 4
      twd.rollback = true
      twd.current_day = false
    })

    // complete current week
    teamWeek.status_id = 3

    // open next week
    let nextWeekIndex = allTeamWeeks.indexOf(teamWeek) + 1
    if (nextWeekIndex < allTeamWeeks.length) {
      let nextWeek = allTeamWeeks[nextWeekIndex]
      // open next week
      nextWeek.status_id = 2

      // open first day
      let monday = nextWeek.team_week_days[0]
      monday.status_id = 3
      monday.rollback = false
      monday.current_day = true
    }
  },
  [types.SUBMIT_NEXTWEEK_FAILURE](state) {},
  [types.FETCH_TEAMWORKALLOCATIONS_SUCCESS](state, items) {
    state.teamWorkAllocations = items
  },
  [types.FETCH_TEAMWORKALLOCATIONS_FAILURE](state) {
    state.teamWorkAllocations = null
  },
  [types.FETCH_TEAMWEEKWORKALLOCATIONS_SUCCESS](state, payload) {
    state.teamWeekWorkAllocations = payload
  },
  [types.FETCH_TEAMWEEKWORKALLOCATIONSSTATUS_SUCCESS](state, payload) {
    state.teamWeekWorkAllocationsStatus = payload
  },
  [types.FETCH_TEAMWORKCOMPLEXITY_SUCCESS](state, payload) {
    state.teamWorkComplexity = payload
  },
  [types.FETCH_TEAMAPPROVALCODEVALIDATION_SUCCESS](state, payload) {
    state.teamApprovalCodeValidation = payload
  },
  [types.SUBMIT_WORKALLOCATIONS_SUCCESS](state, payload) {},
  [types.SUBMIT_WORKALLOCATIONS_FAILURE](state) {},
  [types.FETCH_USERS_MANAGEMENT_SUCCESS](state, items) {
    state.users = items
  },
  [types.FETCH_USERS_MANAGEMENT_FAILURE](state) {
    state.users = null
  },
  [types.FETCH_TEAMS_MANAGEMENT_SUCCESS](state, items) {
    state.teamMaster = items
  },
  [types.FETCH_TEAMS_MANAGEMENT_FAILURE](state) {
    state.teamMaster = null
  },
  [types.FETCH_PROCESSED_SCORES_SUCCESS](state, payload) {
    state.teamProcessedScores = payload
  },
  [types.FETCH_PROCESSED_SCORES_FAILURE](state) {
    state.teamProcessedScores = null
  },
  [types.FETCH_OVERALL_PROFITABILITY_SUCCESS](state, payload) {
    state.overallProfitability = {
      labels: payload.labels,
      datasets: setChartColor(payload.datasets)
    }
  },
  [types.FETCH_OVERALL_VOLUME_SUCCESS](state, payload) {
    state.overallVolume = {
      labels: payload.labels,
      datasets: setChartColor(payload.datasets)
    }
  },
  [types.FETCH_OVERALL_ACCURACY_SUCCESS](state, payload) {
    state.overallAccuracy = {
      labels: payload.labels,
      datasets: setChartColor(payload.datasets)
    }
  },
  [types.FETCH_WEEKLY_PROFITABILITY_SUCCESS](state, payload) {
    state.weeklyProfitability = {
      labels: payload.labels,
      datasets: setChartColor(payload.datasets)
    }
  },
  [types.FETCH_WEEKLY_VOLUME_SUCCESS](state, payload) {
    state.weeklyVolume = {
      labels: payload.labels,
      datasets: setChartColor(payload.datasets)
    }
  },
  [types.FETCH_WEEKLY_ACCURACY_SUCCESS](state, payload) {
    state.weeklyAccuracy = {
      labels: payload.labels,
      datasets: setChartColor(payload.datasets)
    }
  },
  [types.FETCH_ROUND_BREAKDOWN_SUCCESS](state, payload) {
    let fields = payload.fields
    for (let i = 2; i < fields.length; i++) {
      fields[i].formatter = (value) => {
        return formatPayment(value)
      }
    }

    let fieldGroup = payload.fieldGroup.filter((fg) => fg.show)
    let result = setTableHeaderColor(fieldGroup)
    state.roundBreakdown = payload
  },
  [types.ADD_TEAM_SUCCESS](state, payload) {},
  [types.ADD_TEAM_FAILURE](state) {},
  [types.UPDATE_USER_SUCCESS](state, payload) {
    var object = {}
    let submitJsonData = payload.submitJsonData
    const item = state.users.find((item) => item.id === parseInt(submitJsonData.id))
    Object.assign(item, submitJsonData)
  },
  [types.UPDATE_TEAM_SUCCESS](state, payload) {
    var object = {}
    const item = state.teams.find((item) => item.id === parseInt(payload.id))
    Object.assign(item, payload)
  },
  [types.UPDATE_TEAM_FAILURE](state) {},
  [types.RESET_USERS_SUCCESS](state) {
    state.users = null
  },
  [types.TRIGGER_GAME_SUCCESS](state, payload) {
    state.triggeredGame = payload
  },
  [types.FETCH_APP_TITLE_SUCCESS](state, payload) {
    state.appTitle = payload
  },
  [types.FETCH_LOCALE_SUCCESS](state, payload) {
    state.locale = payload
  },
  [types.FETCH_CURRENCY_SUCCESS](state, payload) {
    state.currency = payload
  },
  [types.FETCH_TEAMGAMEFINANCIALS_SUCCESS](state, payload) {
    state.gameFinancials = payload
  },
  [types.ADD_TEAMGAMEFINANCIALS_SUCCESS](state, payload) {},
  [types.FETCH_NEWTEAMGAMEFINANCIALS_SUCCESS](state, payload) {
    state.gameFinancials = payload
  },
  [types.UPDATE_TEAMGAMEFINANCIALS_SUCCESS](state, payload) {
    let gameFinancials = state.gameFinancials
    let gameFinancial = payload[0]
    const index = gameFinancials.findIndex((item) => {
      return item.id === gameFinancial.id
    })
    gameFinancials.splice(index, 1, gameFinancial)
  },
  [types.DELETE_TEAMGAMEFINANCIALS_SUCCESS](state, payload) {
    let gameFinancials = state.gameFinancials
    const index = gameFinancials.findIndex((item) => item.id === payload.id)
    gameFinancials.splice(index, 1)
  },
  [types.ROLLBACK_TEAMWEEKDAY_SUCCESS](state, payload) {
    // update open team week and day
    let allTeamWeekDays = state.allTeamWeekDays
    let teamId = payload.teamId
    if (allTeamWeekDays.team_id === teamId) {
      let teamWeekId = payload.teamWeekId
      let fromWeekDayId = payload.fromWeekDayId
      let teamWeeks = allTeamWeekDays.team_weeks
      const teamWeekIndex = teamWeeks.findIndex((tw) => tw.id === teamWeekId)
      let teamWeek = teamWeeks[teamWeekIndex]
      // set team week status
      teamWeek.status_id = 2
      for (let i = teamWeekIndex + 1; i < teamWeeks.length; i++) {
        let tw = teamWeeks[i]
        tw.status_id = 0
      }

      // set team week day status
      let teamWeekDays = teamWeek.team_week_days
      const teamWeekDayIndex = teamWeekDays.findIndex((twd) => twd.id === fromWeekDayId)
      let teamWeekDay = teamWeekDays[teamWeekDayIndex]
      teamWeekDay.status_id = 3
      teamWeekDay.rollback = false
      for (let i = teamWeekDayIndex + 1; i < teamWeekDays.length; i++) {
        let twd = teamWeekDays[i]
        twd.status_id = 1
        twd.rollback = false
      }
    }
  },
  [types.FETCH_AUDITLOG_SUCCESS](state, payload) {
    // check if audit logs contains new items
    let auditLog = state.auditLog
    const claimIds = auditLog.map((item) => item.claimId)
    payload.forEach((item) => {
      if (!claimIds.includes(item.claimId)) {
        auditLog.push(item)
      }
    })
  },
  [types.FETCH_AUDITLOGFILTERS_SUCCESS](state, payload) {
    state.auditLogFilters = payload
  },
  [types.SET_LANGUAGE](state, payload) {
    app.$i18n.locale = payload
    setCurrentLanguage(payload)
    state.language = payload
  },
  [types.RESET_AUDITLOG_SUCCESS](state, payload) {
    state.auditLog = []
  }
}

const actions = {
  async fetchTeams({ commit, dispatch }) {
    try {
      const { data } = await adminService.getTeams()
      await commit(types.FETCH_TEAMS_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch teams',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAllTeams({ commit, dispatch }) {
    try {
      const { data } = await adminService.getAllTeams()
      await commit(types.FETCH_ALLTEAMS_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch all teams',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAllTeamWeeks({ commit, dispatch }, teamId) {
    try {
      const teamWeeks = await adminService.getTeamWeeks(teamId)
      await commit(types.FETCH_TEAMWEEKS_SUCCESS, teamWeeks.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch team weeks',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchTeamWeekDays({ commit, dispatch }) {
    try {
      const { data } = await adminService.getTeamWeekDays()
      await commit(types.FETCH_TEAMWEEKDAYS_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to retrive current day',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAllTeamWeekDays({ commit, dispatch }, teamId) {
    try {
      const { data } = await adminService.getAllTeamWeekDays(teamId)
      await commit(types.FETCH_ALLTEAMWEEKDAYS_SUCCESS, data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to retrive all team weekdays',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAllTeamWorkAllocations({ commit, dispatch }) {
    try {
      const { data } = await adminService.getWorkAllocations()
      await commit(types.FETCH_TEAMWORKALLOCATIONS_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to retrive work allocation',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchTeamWeekWorkAllocations({ commit, dispatch }, payload) {
    try {
      const work = await adminService.getTeamWeekWorkAllocations(payload.teamId, payload.teamWeekId)
      await commit(types.FETCH_TEAMWEEKWORKALLOCATIONS_SUCCESS, work.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch work allocations',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchTeamWeekWorkAllocationsStatus({ commit, dispatch }, teamWeekId) {
    try {
      const { data } = await adminService.getTeamWeekWorkAllocationsStatus(teamWeekId)
      await commit(types.FETCH_TEAMWEEKWORKALLOCATIONSSTATUS_SUCCESS, data.hasWorkAllocations)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch work allocation status',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchTeamGameSettings({ commit, dispatch }, teamId) {
    try {
      const { data } = await adminService.getGameSettings(teamId)
      await commit(types.FETCH_TEAMWORKCOMPLEXITY_SUCCESS, data.data.workComplexity)
      await commit(types.FETCH_TEAMAPPROVALCODEVALIDATION_SUCCESS, data.data.approvalCodeValidation)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch game settings',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateTeamGameSettings({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.updateGameSettings(payload)
      const notification = {
        type: 'success',
        title: 'Game settings updated',
        message: 'Game settings have been assigned.'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to update game settings',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAllUsers({ commit, dispatch }) {
    try {
      const { data } = await adminService.getUsers()
      await commit(types.FETCH_USERS_MANAGEMENT_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch users',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAllProcessedScores({ commit }) {
    const { data } = await adminService.getProcessedScores()
    await commit(types.FETCH_PROCESSED_SCORES_SUCCESS, data.data)
  },
  async fetchOverallResultsCharts({ commit }) {
    const { data } = await adminService.getOverallResultsCharts()
    await commit(types.FETCH_OVERALL_PROFITABILITY_SUCCESS, data.data.profitability)
    await commit(types.FETCH_OVERALL_VOLUME_SUCCESS, data.data.volume)
    await commit(types.FETCH_OVERALL_ACCURACY_SUCCESS, data.data.accuracy)
  },
  async fetchWeeklyResultsCharts({ commit }) {
    const { data } = await adminService.getWeeklyResultsCharts()
    await commit(types.FETCH_WEEKLY_PROFITABILITY_SUCCESS, data.data.profitability)
    await commit(types.FETCH_WEEKLY_VOLUME_SUCCESS, data.data.volume)
    await commit(types.FETCH_WEEKLY_ACCURACY_SUCCESS, data.data.accuracy)
  },
  async fetchRoundBreakdownTable({ commit }, weekId) {
    const { data } = await adminService.getRoundBreakdownTable(weekId)
    await commit(types.FETCH_ROUND_BREAKDOWN_SUCCESS, data)
  },
  async submitNextWeekDay({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.updateTeamWeekday(payload.nextWeekDay)
      await commit(types.SUBMIT_NEXTWEEKDAY_SUCCESS, payload.updatedWeekDays)
      // return { status: 'success', message: data.message }
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to to move to the next weekday',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async submitNextWeek({ commit, dispatch }, teamWeekId) {
    try {
      const { data } = await adminService.updateTeamWeek({ teamWeekId: teamWeekId })
      await commit(types.SUBMIT_NEXTWEEK_SUCCESS, teamWeekId)
      // return { status: 'success', message: data.message }
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to to move to the next weekday',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async submitWorkAllocations({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.updateWorkAllocations(payload)
      await commit(types.SUBMIT_WORKALLOCATIONS_SUCCESS, payload)
      const notification = {
        type: 'success',
        title: 'Work allocation updated',
        message: 'New work allocation has been assigned.'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to update work allocation',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async submitTeamWeekSettings({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.updateTeamWeekSettings(payload)
      commit(types.FETCH_LOCALE_SUCCESS, payload.appLocale)
      commit(types.FETCH_CURRENCY_SUCCESS, payload.appLocaleCurrency)
      const notification = {
        type: 'success',
        title: 'Game settings have been updated',
        message: 'Adjustments made will now be reflected.'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to update game settings',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async addTeam({ commit, dispatch }, payload) {
    try {
      const formData = payload
      const teamName = formData.get('name')
      const { data } = await adminService.addTeam(formData)
      await commit(types.ADD_TEAM_SUCCESS, formData)
      const notification = {
        type: 'success',
        title: 'Team updated successfully',
        message: `Team ${teamName} is created.`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to create team',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateTeam({ commit, dispatch }, payload) {
    try {
      const formData = payload
      const teamName = formData.get('name')
      const { data } = await adminService.updateTeam(formData)
      const notification = {
        type: 'success',
        title: 'Team has updated successfully',
        message: `Team ${teamName} is now updated.`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to update Team ${teamName}`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async deleteTeam({ commit, dispatch }, team) {
    try {
      const { data } = await adminService.deleteTeam({ id: team.id })
      const notification = {
        type: 'success',
        title: `Team deleted`,
        message: `${team.name} is deleted.`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to delete ${team.name}`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async submitTeamUsersFile({ commit }, payload) {
    try {
      const { data } = await adminService.updateTeamUserFile(payload)
      return { status: 'success', message: data.message }
    } catch (e) {
      return { status: 'error', message: e.response.data.message }
    }
  },
  async addUser({ commit, dispatch }, payload) {
    try {
      const formData = payload
      const firstName = formData.get('first_name')
      const lastName = formData.get('last_name')
      const { data } = await adminService.addUser(formData)
      const notification = {
        type: 'success',
        title: `User created`,
        message: `${firstName} ${lastName} is created.`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to create ${firstName} ${lastName}`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateUser({ commit, dispatch }, payload) {
    try {
      const formData = payload
      const firstName = formData.get('first_name')
      const lastName = formData.get('last_name')
      const { data } = await adminService.updateUser(formData)
      // await commit(types.UPDATE_USER_SUCCESS, payload)
      const notification = {
        type: 'success',
        title: `User updated`,
        message: `${firstName} ${lastName} is updated.`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to update ${firstName} ${lastName}`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async deleteUser({ commit, dispatch }, user) {
    try {
      const deletedUser = { id: user.id }
      const { data } = await adminService.deleteUser(deletedUser)
      const notification = {
        type: 'success',
        title: `User deleted`,
        message: `${user.name} is deleted.`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to delete ${user.name}`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async unblockUser({ commit, dispatch }, payload) {
    try {
      const formData = payload
      const firstName = formData.get('first_name')
      const lastName = formData.get('last_name')
      const { data } = await adminService.unblockUser(formData)
      const notification = {
        type: 'success',
        title: `User unblocked`,
        message: `${firstName} ${lastName} is unblocked.`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to unblock ${firstName} ${lastName}`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async submitApprovalCodesFile({ commit }, payload) {
    try {
      const { data } = await adminService.addApprovalCodesFile(payload)
      return { status: 'success', message: data.message }
    } catch (e) {
      return { status: 'error', message: e.response.data.message }
    }
  },
  async submitClaimsFile({ commit }, payload) {
    try {
      const { data } = await adminService.addClaimsFile(payload)
      return { status: 'success', message: data.message }
    } catch (e) {
      return { status: 'error', message: e.response.data.message }
    }
  },
  async resetSimulationData({ commit, dispatch }) {
    try {
      const { data } = await adminService.resetSimulationData()
      const notification = {
        type: 'success',
        title: `Game data reset`,
        message: data.success
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to reset game data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async resetUsersData({ commit, dispatch }) {
    try {
      const { data } = await adminService.resetUserData()
      await commit(types.RESET_USERS_SUCCESS)
      const notification = {
        type: 'success',
        title: `User data reset`,
        message: data.success
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Failed to reset user data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async triggerGame({ commit, dispatch }, teamWeekDayId) {
    try {
      const { data } = await adminService.triggerGame(teamWeekDayId)
      const status = data.data.status
      await commit(types.TRIGGER_GAME_SUCCESS, data.data)
      const notification = {
        type: 'success',
        title: status === 1 ? 'Game starts' : 'Game completes',
        message:
          status === 1
            ? 'Game has started and will be automatically completed in 90 seconds'
            : 'Game has completed. Please check next day.'
      }
      await dispatch('notification/addNotification', notification, { root: true })

      // return { status: 'success', message: status === 1 ? 'Game starts' : 'Game completes' }
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when triggering game`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAppConfiguration({ commit, dispatch }) {
    try {
      const { data } = await adminService.getAppConfiguration()
      await commit(types.FETCH_APP_TITLE_SUCCESS, data.name)
      await commit(types.FETCH_LOCALE_SUCCESS, data.locale)
      await commit(types.FETCH_CURRENCY_SUCCESS, data.currency)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch default configuration',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchTeamGameFinancials({ commit, dispatch }, teamId) {
    try {
      const { data } = await adminService.getGameFinancials(teamId)
      await commit(types.FETCH_TEAMGAMEFINANCIALS_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch game financials',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async addTeamGameFinancials({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.updateGameFinancials({ data: payload })
      const notification = {
        type: 'success',
        title: 'New game financial saved',
        message: 'New game financial has been added.'
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to add game financials',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchNewTeamGameFinancials({ commit, dispatch }, teamId) {
    try {
      const { data } = await adminService.getGameFinancials(teamId)
      await commit(types.FETCH_NEWTEAMGAMEFINANCIALS_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to fetch game financials',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateTeamGameFinancials({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.updateGameFinancials({ data: payload.post })
      const notification = {
        type: 'success',
        title: 'Game financials updated',
        message: 'Game financials have been assigned.'
      }
      await commit(types.UPDATE_TEAMGAMEFINANCIALS_SUCCESS, payload.state)
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to update game financials',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async deleteTeamGameFinancials({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.updateGameFinancials({ data: payload })
      const notification = {
        type: 'success',
        title: 'Game financial deleted',
        message: 'Game financial has been deleted.'
      }
      await commit(types.DELETE_TEAMGAMEFINANCIALS_SUCCESS, payload)
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to delete game financials',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async rollbackTeamWeekDay({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.rollbackTeamWeekDay(
        payload.teamId,
        payload.fromWeekDayId,
        payload.toWeekDayId
      )
      // await commit(types.ROLLBACK_TEAMWEEKDAY_SUCCESS, payload)
      const notification = {
        type: 'success',
        title: 'Rollback completed',
        message: 'Rollback has been completed.'
      }
      await dispatch('notification/addNotification', notification, { root: true })
      await commit(types.RESET_AUDITLOG_SUCCESS)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Failed to rollback team weekday',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAuditLog({ commit, dispatch }, payload) {
    try {
      const { data } = await adminService.getAuditLog(payload.teamId, payload.lastClaimId)
      await commit(types.FETCH_AUDITLOG_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching audit log`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchAuditLogFilters({ commit, dispatch }) {
    try {
      const { data } = await adminService.getAuditLogFilters()
      await commit(types.FETCH_AUDITLOGFILTERS_SUCCESS, data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching audit filters`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async resetAuditLog({ commit }) {
    await commit(types.RESET_AUDITLOG_SUCCESS)
  },
  async setLanguage({ commit }, payload) {
    await commit(types.SET_LANGUAGE, payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
