import { formatPayment } from '@/constants/config'
export const WHATWENTWELL = [
  {
    id: 1,
    title: 'The reallocation process was easy',
    detail: 'Bryanna Hess',
    thumb: '/assets/img/profile-pic-l.jpg'
  },
  {
    id: 2,
    title: 'Team leader is smashing it',
    detail: 'Cesar Carr',
    thumb: '/assets/img/profile-pic-l-2.jpg'
  },
  {
    id: 3,
    title: 'was easy to take on the two new cases',
    detail: 'Dwayne Mejia',
    thumb: '/assets/img/profile-pic-l-3.jpg'
  },
  {
    id: 4,
    title: 'Team is sharing the load well!',
    detail: 'Jason Ferrell',
    thumb: '/assets/img/profile-pic-l-4.jpg'
  }
]

export const COMMUNICATIONS = [
  {
    id: 1,
    title: 'god sharing workload',
    detail: 'Dwayne Mejia',
    thumb: '/assets/img/profile-pic-l-3.jpg'
  },
  {
    id: 2,
    title: 'Go Team',
    detail: 'Jason Ferrell',
    thumb: '/assets/img/profile-pic-l-4.jpg'
  }
]

export const FEEDBACK = [
  {
    id: 1,
    title: 'Codes is definitely a lag. Might be worth paying for an update',
    detail: 'Cara Brewer',
    thumb: '/assets/img/profile-pic-l-5.jpg'
  }
]

export const PROBLEMS = [
  {
    id: 1,
    title: 'Slow systems',
    type: 'Problem',
    raisedBy: 'Cesar Carr',
    dayRaised: 'Wednesday',
    roundRaised: 'Week 2',
    status: 'Complete'
  },
  {
    id: 2,
    title: 'Lodgement form is not easy to use',
    type: 'Problem',
    raisedBy: 'Jason Ferrell',
    dayRaised: 'Thursday',
    roundRaised: 'Week 2',
    status: 'Open'
  },
  {
    id: 3,
    title: 'may be able to offload one claim',
    type: 'Problem',
    raisedBy: 'Adrianna Mcclain',
    dayRaised: 'Tuesday',
    roundRaised: 'Week 3',
    status: 'Open'
  },
  {
    id: 4,
    title: 'I need another',
    type: 'Problem',
    raisedBy: 'Dwayne Mejia',
    dayRaised: 'Thursday',
    roundRaised: 'Week 3',
    status: 'Open'
  },
  {
    id: 5,
    title: 'I have no cases left',
    type: 'Problem',
    raisedBy: 'Dwayne Mejia',
    dayRaised: 'Thursday',
    roundRaised: 'Week 3',
    status: 'Open'
  }
]

export const ACTIONS = [
  {
    id: 1,
    title: 'Look into buying that approvals bypass',
    type: 'Action',
    raisedBy: 'Catalina Dixon',
    dayRaised: 'Thursday',
    roundRaised: 'Week 3',
    status: 'Open'
  }
]

export const STATUS = {
  options: [
    {
      id: 1,
      label: 'Open'
    },
    {
      id: 2,
      label: 'Complete'
    }
  ],
  // options: ["Open", "Complete"],
  selectForm: {
    single: ''
  }
}

export const WEEKLYDATA = {
  fields: [
    {
      name: 'round',
      title: 'Week',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'processed',
      title: '# Processed',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'passed',
      title: '# Passed',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'failed',
      title: '# Failed',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'incorrectName',
      title: '# Incorrect Name',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'wrongPolicyType',
      title: '# Wrong Policy Type',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'wrongPolicyName',
      title: '# Wrong Policy Name',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'decisionIncorrect',
      title: '# Decision Incorrect',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'paymentAmountIncorrect',
      title: '# Payment Amount Incorrect',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'pass',
      title: 'Pass %',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'bonuses',
      title: 'Bonuses',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'additionalCost',
      title: 'Additional Costs (WIP, Purchases)',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'totalRevenue',
      title: 'Total Revenue',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'totalCost',
      title: 'Total Cost',
      titleClass: '',
      dataClass: 'text-muted'
    },
    {
      name: 'totalProfit',
      title: 'Total Profit',
      titleClass: '',
      dataClass: 'text-muted'
    }
  ],
  data: [
    {
      round: 'Week 1',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      round: 'Week 2',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      round: 'Week 3',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      round: 'Week 4',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      round: 'Total',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    }
  ]
}

export const YESTERDAYDATA = {
  data: [
    {
      claimOfficerId: 'MCC117 - Adrianna Mcclain',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      claimOfficerId: 'HES122 - Bryanna Hess',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      claimOfficerId: 'BRE108 - Cara Brewer',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      claimOfficerId: 'DIX113 - Catalina Dixon',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    },
    {
      claimOfficerId: 'CAR103 - Cesar Carr',
      processed: 36,
      passed: 10,
      failed: 26,
      incorrectName: 5,
      wrongPolicyType: 0,
      wrongPolicyNumber: 0,
      decisionIncorrect: 8,
      paymentAmountIncorrect: 19,
      pass: '28%',
      bonuses: 0,
      additionalCost: -250,
      totalRevenue: 200,
      totalCost: -710,
      totalProfit: -510
    }
  ]
}

export const PEOPLE = [
  {
    id: 1,
    title: 'Catalina Dixon',
    score: 6
  },
  {
    id: 2,
    title: 'Adrianna Mcclain',
    score: 8
  },
  {
    id: 3,
    title: 'Cesar Carr',
    score: 9
  },
  {
    id: 4,
    title: 'Jason Ferrell',
    score: 8
  },
  {
    id: 5,
    title: 'Korbin Nelson',
    score: 6
  },
  {
    id: 6,
    title: 'Bryanna Hess',
    score: 7
  },
  {
    id: 7,
    title: 'Dwayne Mejia',
    score: 5
  },
  {
    id: 8,
    title: 'Sarah Kortney',
    score: 9
  }
]

export const ROUNDBREAKDOWN = {
  fieldGroup: [
    {
      items: 1,
      show: false,
      title: ''
    },
    {
      items: 1,
      show: true,
      title: 'Team A'
    },
    {
      items: 1,
      show: true,
      title: 'Team B'
    },
    {
      items: 1,
      show: true,
      title: 'Team C'
    },
    {
      items: 1,
      show: true,
      title: 'Team D'
    },
    {
      items: 1,
      show: true,
      title: 'Team E'
    }
  ],
  fields: [
    {
      key: 'type',
      label: '',
      thClass: 'd-none',
      tdClass: 'd-none'
    },
    {
      key: 'options',
      label: '',
      thClass: 'd-none'
    },
    {
      key: 'team_a',
      label: 'Team A',
      thClass: 'd-none',
      formatter: value => {
        return formatPayment(value)
      }
    },
    {
      key: 'team_b',
      label: 'Team B',
      thClass: 'd-none',
      formatter: value => {
        return formatPayment(value)
      }
    },
    {
      key: 'team_c',
      label: 'Team C',
      thClass: 'd-none',
      formatter: value => {
        return formatPayment(value)
      }
    },
    {
      key: 'team_d',
      label: 'Team D',
      thClass: 'd-none',
      formatter: value => {
        return formatPayment(value)
      }
    },
    {
      key: 'team_e',
      label: 'Team E',
      thClass: 'd-none',
      formatter: value => {
        return formatPayment(value)
      }
    }
  ],
  data: [
    {
      type: 'Revenue',
      options: 'Correct Claims',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    },
    {
      type: 'Revenue',
      options: 'Credits Applied',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    },
    {
      type: 'Revenue',
      options: 'Total Revenue',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    },
    {
      type: 'Costs',
      options: 'Incorrect Claims',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    },
    {
      type: 'Costs',
      options: 'WIP / Remaining Claims',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    },
    {
      type: 'Costs',
      options: 'Wages & Overheads',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    },
    {
      type: 'Costs',
      options: 'Other Debits',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    },
    {
      type: 'Costs',
      options: 'Total Costs',
      team_a: randomValue(),
      team_b: randomValue(),
      team_c: randomValue(),
      team_d: randomValue(),
      team_e: randomValue()
    }
  ]
}

// functions
function randomValue() {
  return Math.floor(Math.random() * 100 + 1)
}
