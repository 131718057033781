import axios from 'axios'
import * as types from '../mutation-types'
import { systemService } from '@/services'

const state = {
  teamWeeks: null,
  openTeamWeek: null
}

const getters = {
  teamWeeks: (state) => state.teamWeeks.team_weeks,
  openTeamWeek: (state) => state.openTeamWeek,
  currentTeamWeek: (state) => {
    return state.teamWeeks.team_weeks.find((tw) => tw.open_day_id)
  },
  approvalCodeValidation: (state) => {
    return state.openTeamWeek.approval_code
  },
  workComplexity: (state) => {
    return state.openTeamWeek.work_complexity
  }
}

const mutations = {
  [types.FETCH_TEAMWEEKS_SUCCESS](state, items) {
    state.teamWeeks = items
  },
  [types.FETCH_TEAMWEEKS_FAILURE](state) {
    state.teamWeeks = []
  },
  [types.RESET_TEAMWEEKS_SUCCESS](state, items) {
    state.teamWeeks = items
  },
  [types.RESET_TEAMWEEKS_FAILURE](state) {
    state.teamWeeks = []
  },
  [types.FETCH_OPENTEAMWEEK_SUCCESS](state, payload) {
    state.openTeamWeek = payload
  },
  [types.SET_OPENTEAMWEEK](state, payload) {
    state.openTeamWeek = payload
  },
  [types.UPDATE_APPROVALCODE_SUCCESS](state, payload) {
    state.openTeamWeek.approval_code = payload
  },
  [types.UPDATE_WORKCOMPLEXITY_SUCCESS](state, payload) {
    state.openTeamWeek.work_complexity = payload
  }
}

const actions = {
  async fetchTeamWeeks({ commit, dispatch }) {
    try {
      const teamWeeks = await systemService.getTeamWeeks()
      await commit(types.FETCH_TEAMWEEKS_SUCCESS, teamWeeks.data.data[0])
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching team weeks`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async fetchOpenTeamWeek({ commit, dispatch }) {
    try {
      const { data } = await systemService.getOpenWeek()
      await commit(types.FETCH_OPENTEAMWEEK_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching open team week`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async resetTeamWeeks({ commit }, teamWeeks) {
    try {
      await commit(types.RESET_TEAMWEEKS_SUCCESS, teamWeeks)
    } catch (e) {
      await commit(types.RESET_TEAMWEEKS_FAILURE)
    }
  },
  async setOpenTeamWeek({ commit }, teamWeek) {
    await commit(types.SET_OPENTEAMWEEK, teamWeek)
    return { status: 'success', message: 'Open team week is updated' }
  },
  async updateApprovalCode({ commit, dispatch }, approvalCodeStatus) {
    await commit(types.UPDATE_APPROVALCODE_SUCCESS, approvalCodeStatus)
    const notification = {
      type: 'success',
      title: `Approval code updated`,
      message: `Approval code validation is ${approvalCodeStatus === 0 ? 'off' : 'on'}`
    }
    await dispatch('notification/addNotification', notification, { root: true })
  },
  async updateWorkComplexity({ commit, dispatch }, workComplexity) {
    await commit(types.UPDATE_WORKCOMPLEXITY_SUCCESS, workComplexity)
    const notification = {
      type: 'success',
      title: `Work complexity updated`,
      message: `${workComplexity === 1 ? 'Table' : 'Letter'} mode is on.`
    }
    await dispatch('notification/addNotification', notification, { root: true })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
