<template>
  <div class="h-100">
    <!-- <notification-container></notification-container> -->
    <router-view />
  </div>
</template>

<script>
// import NotificationContainer from '@/components/Notification/NotificationContainer.vue'
export default {
  components: {
    // NotificationContainer
  }
}
</script>
