import * as types from '../mutation-types';

const state = {
  error: ""
}

const getters = {
  error: state => state.error
}

const mutations = {
  [types.POST_ERROR](state, payload) {
    state.error = payload;
  }
}

const actions = {
  async setError({ commit }, errMsg) {
    await commit(types.POST_ERROR, errMsg);
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}