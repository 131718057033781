import apiClient from '@/services'

const getTeamWeeks = () => {
  return apiClient.get('/systems')
}

const getOpenWeek = () => {
  return apiClient.get('/open-week')
}

const getWorkAllocations = (teamWeekId) => {
  return apiClient.get(`/work-allocation/${teamWeekId}`)
}

const getGame = (teamWeekDayId) => {
  return apiClient.get(`/game-play-status/${teamWeekDayId}`)
}

const addClaim = (data) => {
  return apiClient.post('/simulate', data)
}

export { getTeamWeeks, getOpenWeek, getWorkAllocations, getGame, addClaim }
