import * as types from '../mutation-types'
import { createUUID } from '@/constants/config'

const namespaced = true

const state = {
  notifications: [],
  authNotifications: []
}

const getters = {}

const mutations = {
  [types.PUSH_NOTIFICATIONS](state, payload) {
    const uuid = createUUID()
    state.notifications.push({
      ...payload,
      id: uuid
    })
  },
  [types.DELETE_NOTIFICATIONS](state, payload) {
    state.notifications = state.notifications.filter(
      (notification) => notification.id !== payload.id
    )
  },
  [types.PUSH_AUTH_NOTIFICATIONS](state, payload) {
    const uuid = createUUID()
    state.authNotifications.push({
      ...payload,
      id: uuid
    })
  },
  [types.DELETE_AUTH_NOTIFICATIONS](state, payload) {
    state.authNotifications = state.authNotifications.filter(
      (notification) => notification.id !== payload.id
    )
  },
  [types.RESET_NOTIFICATIONS](state, payload) {
    state.notifications = []
  }
}

const actions = {
  addNotification({ commit }, notification) {
    commit(types.PUSH_NOTIFICATIONS, notification)
  },
  removeNotification({ commit }, notificationToRemove) {
    commit(types.DELETE_NOTIFICATIONS, notificationToRemove)
  },
  addAuthNotification({ commit }, notification) {
    commit(types.PUSH_AUTH_NOTIFICATIONS, notification)
  },
  removeAuthNotification({ commit }, notificationToRemove) {
    commit(types.DELETE_AUTH_NOTIFICATIONS, notificationToRemove)
  },
  resetNotifications({ commit }) {
    commit(types.RESET_NOTIFICATIONS)
  }
}

export default {
  namespaced,
  state,
  getters,
  mutations,
  actions
}
