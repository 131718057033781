// auth
export const SET_USER_DATA = 'SET_USER_DATA'
export const LOGOUT = 'LOGOUT'
export const SAVE_TOKEN = 'SAVE_TOKEN'
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS'
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE'
export const UPDATE_USER_ONBOARDING_SUCCESS = 'UPDATE_USER_ONBOARDING_SUCCESS'
export const UPDATE_USER_ONBOARDING_FAILURE = 'UPDATE_USER_ONBOARDING_FAILURE'
export const SET_LOGIN_ERROR = 'SET_LOGIN_ERROR'

// team week
export const FETCH_TEAMWEEKS_SUCCESS = 'FETCH_TEAMWEEKS_SUCCESS'
export const FETCH_TEAMWEEKS_FAILURE = 'FETCH_TEAMWEEKS_FAILURE'
export const RESET_TEAMWEEKS_SUCCESS = 'RESET_TEAMWEEKS_SUCCESS'
export const RESET_TEAMWEEKS_FAILURE = 'RESET_TEAMWEEKS_FAILURE'
export const FETCH_OPENTEAMWEEK_SUCCESS = 'FETCH_OPENTEAMWEEK_SUCCESS'
export const SET_OPENTEAMWEEK = 'SET_OPENTEAMWEEK'
export const UPDATE_APPROVALCODE_SUCCESS = 'UPDATE_APPROVALCODE_SUCCESS'
export const UPDATE_WORKCOMPLEXITY_SUCCESS = 'UPDATE_WORKCOMPLEXITY_SUCCESS'

// claim lodgement
export const FETCH_CLAIMS = 'FETCH_CLAIMS'
export const FETCH_CLAIMS_SUCCESS = 'FETCH_CLAIMS_SUCCESS'
export const FETCH_CLAIMS_FAILURE = 'FETCH_CLAIMS_FAILURE'
export const SAVE_CLAIM = 'SAVE_CLAIM'
export const SAVE_CLAIM_SUCCESS = 'SAVE_CLAIM_SUCCESS'
export const SAVE_CLAIM_FAILURE = 'SAVE_CLAIM_FAILURE'
export const FETCH_GAME_SUCCESS = 'FETCH_GAME_SUCCESS'
export const UPDATE_TEMPCLAIM = 'UPDATE_TEMPCLAIM'
export const RESET_TEMPCLAIM = 'RESET_TEMPCLAIM'

// work allocation
export const FETCH_WORKALLOCATIONS = 'FETCH_WORKALLOCATIONS'
export const FETCH_WORKALLOCATIONS_SUCCESS = 'FETCH_WORKALLOCATIONS_SUCCESS'
export const FETCH_WORKALLOCATIONS_FAILURE = 'FETCH_WORKALLOCATIONS_FAILURE'
export const UPDATE_WORKALLOCATIONS_SUCCESS = 'UPDATE_WORKALLOCATIONS_SUCCESS'
export const UPDATE_WORKALLOCATIONS_FAILURE = 'UPDATE_WORKALLOCATIONS_FAILURE'
export const RESET_WORKALLOCATIONS_SUCCESS = 'RESET_WORKALLOCATIONS_SUCCESS'
export const RESET_WORKALLOCATIONS_FAILURE = 'RESET_WORKALLOCATIONS_FAILURE'
export const SAVE_CURRENT_WORKALLOCATION_SUCCESS = 'SAVE_CURRENT_WORKALLOCATION_SUCCESS'
export const SAVE_CURRENT_WORKALLOCATION_FAILURE = 'SAVE_CURRENT_WORKALLOCATION_FAILURE'
export const COMPLETE_WORKALLOCATION_SUCCESS = 'COMPLETE_WORKALLOCATION_SUCCESS'

// vmb
export const FETCH_VMBDATA_SUCCESS = 'FETCH_VMBDATA_SUCCESS'
export const FETCH_VMBDATA_FAILURE = 'FETCH_VMBDATA_FAILURE'
export const FETCH_VMBWHATWENTWELLDATA_SUCCESS = 'FETCH_VMBWHATWENTWELLDATA_SUCCESS'
export const FETCH_VMBWHATWENTWELLDATA_FAILURE = 'FETCH_VMBWHATWENTWELLDATA_FAILURE'
export const FETCH_VMBPROBLEMSDATA_SUCCESS = 'FETCH_VMBPROBLEMSDATA_SUCCESS'
export const FETCH_VMBPROBLEMSDATA_FAILURE = 'FETCH_VMBPROBLEMSDATA_FAILURE'
export const FETCH_VMBACTIONSDATA_SUCCESS = 'FETCH_VMBACTIONSDATA_SUCCESS'
export const FETCH_VMBACTIONSDATA_FAILURE = 'FETCH_VMBACTIONSDATA_FAILURE'
export const FETCH_VMBSTATSDATA_SUCCESS = 'FETCH_VMBSTATSDATA_SUCCESS'
export const FETCH_VMBSTATSDATA_FAILURE = 'FETCH_VMBSTATSDATA_FAILURE'
export const FETCH_VMBOBSERVATIONDATA_SUCCESS = 'FETCH_VMBOBSERVATIONDATA_SUCCESS'
export const FETCH_VMBOBSERVATIONDATA_FAILURE = 'FETCH_VMBOBSERVATIONDATA_FAILURE'
export const UPDATE_VMBOBSERVATIONSTATUS_SUCCESS = 'UPDATE_VMBOBSERVATIONSTATUS_SUCCESS'
export const UPDATE_VMBOBSERVATIONSTATUS_FAILURE = 'UPDATE_VMBOBSERVATIONSTATUS_FAILURE'
export const SAVE_VMBITEMS_SUCCESS = 'SAVE_VMBITEMS_SUCCESS'
export const SAVE_VMBITEMS_FAILURE = 'SAVE_VMBITEMS_FAILURE'
export const FETCH_VMBWEEKLYDATA_SUCCESS = 'FETCH_VMBWEEKLYDATA_SUCCESS'
export const FETCH_VMBWEEKLYDATA_FAILURE = 'FETCH_VMBWEEKLYDATA_FAILURE'
export const FETCH_VMBYESTERDAYDATA_SUCCESS = 'FETCH_VMBYESTERDAYDATA_SUCCESS'
export const FETCH_VMBYESTERDAYDATA_FAILURE = 'FETCH_VMBYESTERDAYDATA_FAILURE'
export const FETCH_VMBSTATUS_SUCCESS = 'FETCH_VMBSTATUS_SUCCESS'
export const FETCH_VMBSTATUS_FAILURE = 'FETCH_VMBSTATUS_FAILURE'

// charts
export const FETCH_VMBCHARTSDATA_SUCCESS = 'FETCH_VMBCHARTSDATA_SUCCESS'
export const FETCH_VMBCHARTSDATA_FAILURE = 'FETCH_VMBCHARTSDATA_FAILURE'
export const FETCH_CLAIMSPROCESSEDCHART_SUCCESS = 'FETCH_CLAIMSPROCESSEDCHART_SUCCESS'
export const FETCH_CLAIMSPROCESSEDCHART_FAILURE = 'FETCH_CLAIMSPROCESSEDCHART_FAILURE'
export const FETCH_ACCURACYCHART_SUCCESS = 'FETCH_ACCURACYCHART_SUCCESS'
export const FETCH_ACCURACYCHART_FAILURE = 'FETCH_ACCURACYCHART_FAILURE'
export const FETCH_PROFITABILITYCHART_SUCCESS = 'FETCH_PROFITABILITYCHART_SUCCESS'

// error
export const SET_ERROR = 'SET_ERROR'
export const POST_ERROR = 'POST_ERROR'

/* admin */
// configuration
export const FETCH_APP_TITLE_SUCCESS = 'FETCH_APP_TITLE_SUCCESS'
export const FETCH_LOCALE_SUCCESS = 'FETCH_LOCALE_SUCCESS'
export const FETCH_CURRENCY_SUCCESS = 'FETCH_CURRENCY_SUCCESS'
export const FETCH_TEAMWORKCOMPLEXITY_SUCCESS = 'FETCH_TEAMWORKCOMPLEXITY_SUCCESS'
export const UPDATE_TEAMWORKCOMPLEXITY_SUCCESS = 'UPDATE_TEAMWORKCOMPLEXITY_SUCCESS'
export const FETCH_TEAMAPPROVALCODEVALIDATION_SUCCESS = 'FETCH_TEAMAPPROVALCODEVALIDATION_SUCCESS'
export const UPDATE_TEAMAPPROVALCODEVALIDATION_SUCCESS = 'UPDATE_TEAMAPPROVALCODEVALIDATION_SUCCESS'
export const FETCH_TEAMGAMEFINANCIALS_SUCCESS = 'FETCH_TEAMGAMEFINANCIALS_SUCCESS'
export const ADD_TEAMGAMEFINANCIALS_SUCCESS = 'ADD_TEAMGAMEFINANCIALS_SUCCESS'
export const FETCH_NEWTEAMGAMEFINANCIALS_SUCCESS = 'FETCH_NEWTEAMGAMEFINANCIALS_SUCCESS'
export const UPDATE_TEAMGAMEFINANCIALS_SUCCESS = 'UPDATE_TEAMGAMEFINANCIALS_SUCCESS'
export const DELETE_TEAMGAMEFINANCIALS_SUCCESS = 'DELETE_TEAMGAMEFINANCIALS_SUCCESS'
export const FETCH_PROFITABILITYCHART_FAILURE = 'FETCH_PROFITABILITYCHART_FAILURE'
export const FETCH_AUDITLOG_SUCCESS = 'FETCH_AUDITLOG_SUCCESS'
export const FETCH_AUDITLOGFILTERS_SUCCESS = 'FETCH_AUDITLOGFILTERS_SUCCESS'
export const RESET_AUDITLOG_SUCCESS = 'RESET_AUDITLOG_SUCCESS'
export const SET_LANGUAGE = 'SET_LANGUAGE'

// teams
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS'
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE'
export const FETCH_ALLTEAMS_SUCCESS = 'FETCH_ALLTEAMS_SUCCESS'
export const FETCH_TEAMWEEKDAYS_SUCCESS = 'FETCH_TEAMWEEKDAYS_SUCCESS'
export const FETCH_TEAMWEEKDAYS_FAILURE = 'FETCH_TEAMWEEKDAYS_FAILURE'
export const FETCH_ALLTEAMWEEKS_SUCCESS = 'FETCH_ALLTEAMWEEKS_SUCCESS'
export const FETCH_ALLTEAMWEEKDAYS_SUCCESS = 'FETCH_ALLTEAMWEEKDAYS_SUCCESS'
export const FETCH_TEAMWORKALLOCATIONS_SUCCESS = 'FETCH_TEAMWORKALLOCATIONS_SUCCESS'
export const FETCH_TEAMWORKALLOCATIONS_FAILURE = 'FETCH_TEAMWORKALLOCATIONS_FAILURE'
export const FETCH_TEAMWEEKWORKALLOCATIONS_SUCCESS = 'FETCH_TEAMWEEKWORKALLOCATIONS_SUCCESS'
export const FETCH_TEAMWEEKWORKALLOCATIONSSTATUS_SUCCESS =
  'FETCH_TEAMWEEKWORKALLOCATIONSSTATUS_SUCCESS'
export const SUBMIT_NEXTWEEKDAY_SUCCESS = 'SUBMIT_NEXTWEEKDAY_SUCCESS'
export const SUBMIT_NEXTWEEKDAY_FAILURE = 'SUBMIT_NEXTWEEKDAY_FAILURE'
export const SUBMIT_NEXTWEEK_SUCCESS = 'SUBMIT_NEXTWEEK_SUCCESS'
export const SUBMIT_NEXTWEEK_FAILURE = 'SUBMIT_NEXTWEEK_FAILURE'
export const SUBMIT_WORKALLOCATIONS_SUCCESS = 'SUBMIT_WORKALLOCATIONS_SUCCESS'
export const SUBMIT_WORKALLOCATIONS_FAILURE = 'SUBMIT_WORKALLOCATIONS_FAILURE'
export const FETCH_ALLWEEKSBYTEAM_SUCCESS = 'FETCH_ALLWEEKSBYTEAM_SUCCESS'

// management
export const FETCH_USERS_MANAGEMENT_SUCCESS = 'FETCH_USERS_MANAGEMENT_SUCCESS'
export const FETCH_USERS_MANAGEMENT_FAILURE = 'FETCH_USERS_MANAGEMENT_FAILURE'
export const FETCH_TEAMS_MANAGEMENT_SUCCESS = 'FETCH_TEAMS_MANAGEMENT_SUCCESS'
export const FETCH_TEAMS_MANAGEMENT_FAILURE = 'FETCH_TEAMS_MANAGEMENT_FAILURE'
export const UPDATE_TEAM_SUCCESS = 'UPDATE_TEAM_SUCCESS'
export const UPDATE_TEAM_FAILURE = 'UPDATE_TEAM_FAILURE'
export const ADD_TEAM_SUCCESS = 'ADD_TEAM_SUCCESS'
export const ADD_TEAM_FAILURE = 'ADD_TEAM_FAILURE'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS'
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE'
export const TRIGGER_GAME_SUCCESS = 'TRIGGER_GAME_SUCCESS'

// score centre
export const FETCH_PROCESSED_SCORES_SUCCESS = 'FETCH_PROCESSED_SCORES_SUCCESS'
export const FETCH_PROCESSED_SCORES_FAILURE = 'FETCH_PROCESSED_SCORES_FAILURE'
export const FETCH_PASSED_SCORES_SUCCESS = 'FETCH_PASSED_SCORES_SUCCESS'
export const FETCH_PASSED_SCORES_FAILURE = 'FETCH_PASSED_SCORES_FAILURE'
export const FETCH_OVERALL_PROFITABILITY_SUCCESS = 'FETCH_OVERALL_PROFITABILITY_SUCCESS'
export const FETCH_OVERALL_PROFITABILITY_FAILURE = 'FETCH_OVERALL_PROFITABILITY_FAILURE'
export const FETCH_OVERALL_VOLUME_SUCCESS = 'FETCH_OVERALL_VOLUME_SUCCESS'
export const FETCH_OVERALL_VOLUME_FAILURE = 'FETCH_OVERALL_VOLUME_FAILURE'
export const FETCH_OVERALL_ACCURACY_SUCCESS = 'FETCH_OVERALL_ACCURACY_SUCCESS'
export const FETCH_OVERALL_ACCURACY_FAILURE = 'FETCH_OVERALL_ACCURACY_FAILURE'
export const FETCH_WEEKLY_PROFITABILITY_SUCCESS = 'FETCH_WEEKLY_PROFITABILITY_SUCCESS'
export const FETCH_WEEKLY_PROFITABILITY_FAILURE = 'FETCH_WEEKLY_PROFITABILITY_FAILURE'
export const FETCH_WEEKLY_VOLUME_SUCCESS = 'FETCH_WEEKLY_VOLUME_SUCCESS'
export const FETCH_WEEKLY_VOLUME_FAILURE = 'FETCH_WEEKLY_VOLUME_FAILURE'
export const FETCH_WEEKLY_ACCURACY_SUCCESS = 'FETCH_WEEKLY_ACCURACY_SUCCESS'
export const FETCH_WEEKLY_ACCURACY_FAILURE = 'FETCH_WEEKLY_ACCURACY_FAILURE'
export const FETCH_ROUND_BREAKDOWN_SUCCESS = 'FETCH_ROUND_BREAKDOWN_SUCCESS'

// reset data
export const RESET_USERS_SUCCESS = 'RESET_USERS_SUCCESS'
export const ROLLBACK_TEAMWEEKDAY_SUCCESS = 'ROLLBACK_TEAMWEEKDAY_SUCCESS'

// notifications
export const PUSH_NOTIFICATIONS = 'PUSH_NOTIFICATIONS'
export const DELETE_NOTIFICATIONS = 'DELETE_NOTIFICATIONS'
export const PUSH_AUTH_NOTIFICATIONS = 'PUSH_AUTH_NOTIFICATIONS'
export const DELETE_AUTH_NOTIFICATIONS = 'DELETE_AUTH_NOTIFICATIONS'
export const RESET_NOTIFICATIONS = 'RESET_NOTIFICATIONS'
