import apiClient from '@/services'

const login = data => {
  return apiClient.post('/login', data)
}

const getUser = () => {
  return apiClient.get('/user')
}

const setOnBoarding = () => {
  return apiClient.post('/onboard')
}

const logout = () => {
  return apiClient.post('/logout')
}

export { login, getUser, setOnBoarding, logout }
