import { ThemeColors } from '../utils'

export const setChartColor = (datasets) => {
  const colors = ThemeColors()
  let themeColorIndex = 0
  let borderColor = ''
  let backgroundColor = ''
  datasets.forEach((d, index) => {
    themeColorIndex = (index % 8) + 1
    borderColor = `themeColor${themeColorIndex}`
    backgroundColor = `themeColor${themeColorIndex}_10`
    d.borderColor = colors[borderColor]
    d.backgroundColor = colors[backgroundColor]
    d.fill = false
    d.borderWidth = 1
  })

  return datasets
}

export const setTableHeaderColor = (datasets) => {
  const colors = ThemeColors()
  let themeColorIndex = 0
  let borderColor = ''
  let backgroundColor = ''
  datasets.forEach((d, index) => {
    if (d.show) {
      themeColorIndex = (index % 8) + 1
      borderColor = `themeColor${themeColorIndex}`
      backgroundColor = `themeColor${themeColorIndex}_10`
      d.borderColor = colors[borderColor]
      d.backgroundColor = colors[backgroundColor]
    }
  })

  return datasets
}
