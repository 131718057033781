import store from '@/store'
import { role } from '@/constants/config'

export default (to, from, next) => {
  // redirect to login page if not logged in and trying to access a restricted page
  const meta = to.meta
  const currentUser = store.getters.currentUser
  if (meta) {
    if (!currentUser) {
      // not logged in so redirect to login page with the return url
      localStorage.clear()
      return next({ path: '/user/login' })
    }

    // check if route is restricted by role
    if (meta.authorize.length && !meta.authorize.includes(currentUser.role)) {
      // role not authorised so redirect to home page
      return next({ path: '/' })
    }
  }

  next()
}
