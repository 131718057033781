import Vue from 'vue'
import Vuex from 'vuex'
import modules from './modules'
import persistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export const initialStoreModules = modules

export default new Vuex.Store({
  state: {},
  mutations: {
    // reset default state modules by looping around the initialStoreModules
    resetState(state) {
      _.forOwn(initialStoreModules, (value, key) => {
        state[key] = _.cloneDeep(value.state)
      })
    }
  },
  actions: {},
  modules: _.cloneDeep(initialStoreModules),
  plugins: [
    persistedState({
      key: 'auth',
      paths: ['user'],
      reducer(val) {
        if (val.user.loginStatus === false) {
          return {}
        }
        return { user: val.user }
      }
    }),
    persistedState({
      key: 'locale',
      paths: ['admin'],
      reducer(val) {
        if (val.user.loginStatus === false) {
          return {}
        }
        return val.admin.locale
      }
    })
  ]
})
