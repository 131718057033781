export const disconnect = () => {
  if (window.Pusher) {
    let pusher = window.Pusher
    let channels = pusher.channels.channels
    if (channels.length > 0) {
      channels.forEach((channel) => {
        pusher.unsubscribe(channel.name)
      })
      pusher.disconnect()
    }
  }
}
