import apiClient from '@/services'

const getVmbData = () => {
  return apiClient.get('/dashboard/survey')
}

const getVmbObservationData = () => {
  return apiClient.get('/dashboard/observation')
}

const addVmbItem = (data) => {
  return apiClient.post('/dashboard/survey', data)
}

const updateVmbObservationStatus = (data) => {
  return apiClient.patch('/dashboard/observation', data)
}

const getVmbWeeklyData = () => {
  return apiClient.get('/dashboard/weekly-data')
}

const getVmbYesterdayData = (weekId) => {
  return apiClient.get(`/dashboard/individual-performance/${weekId}`)
}

const getVmbChartData = () => {
  return apiClient.get('/dashboard/graphs')
}

export {
  getVmbData,
  getVmbObservationData,
  addVmbItem,
  updateVmbObservationStatus,
  getVmbWeeklyData,
  getVmbYesterdayData,
  getVmbChartData
}
