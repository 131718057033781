import axios from 'axios'
import * as types from '../mutation-types'
import { userService } from '@/services'

const state = {
  currentUser: null,
  loginError: null,
  token: null,
  loginStatus: false
}
const getters = {
  currentUser: (state) => state.currentUser,
  loginError: (state) => state.loginError,
  token: (state) => state.token,
  loginStatus: (state) => state.loginStatus,
  authCheck: (state) => state.loginStatus !== false
}
const mutations = {
  [types.SET_USER_DATA](state, payload) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${payload.token}`
    state.token = payload.token
    state.loginStatus = true
  },
  [types.SAVE_TOKEN](state, token) {
    state.token = token
    state.loginStatus = true
  },
  [types.FETCH_USER_SUCCESS](state, user) {
    state.currentUser = user
    state.loginStatus = true
    state.loginError = null
  },
  [types.UPDATE_USER_ONBOARDING_SUCCESS](state) {
    state.currentUser.onboarding = false
  },
  [types.LOGOUT](state) {
    ;(state.currentUser = null),
      (state.loginError = null),
      (state.token = null),
      (state.loginStatus = false)
  },
  [types.SET_LOGIN_ERROR](state, error) {
    console.log('set login error')
    state.loginError = error
  },
  setLoginError(state, error) {
    console.log('set login error')
    state.loginError = error
  }
}
const actions = {
  async login({ commit, dispatch }, payload) {
    const params = {
      email: payload.email,
      password: payload.password
    }

    try {
      const user = await userService.login(payload)
      await commit('SET_USER_DATA', user.data)
      return { status: user.status }
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error ${error.response.status}`,
        message: error.response.data.message
      }
      await dispatch('notification/addAuthNotification', notification, { root: true })
      return { status: 'error', message: error.response.data.message }
    }
  },
  async fetchProfile({ commit, dispatch }) {
    try {
      let currentUser = null
      const user = await userService.getUser()
      const profile = user.data.data

      currentUser = {
        id: profile.id,
        img: profile.avatar,
        role: profile.role,
        onboarding: profile.status === 0 ? true : false,
        teamId: profile.team_id,
        team: profile.team_name,
        teamImg: profile.team_avatar,
        title: profile.full_name
      }

      if (_.has(profile, 'role')) {
        let role = profile.role.toLowerCase()
        if (role === 'super admin' || role === 'admin') {
          currentUser.role = 'admin'
        } else if (role === 'team leader') {
          currentUser.role = 'leader'
        } else {
          currentUser.role = 'member'
        }
      }

      await commit(types.FETCH_USER_SUCCESS, currentUser)

      const notification = {
        type: 'warning',
        title: 'Welcome',
        message: `${currentUser.title}`
      }
      await dispatch('notification/addNotification', notification, { root: true })
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Error occurs when fetching user profile',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async updateUserOnboarding({ commit }) {
    try {
      const { data } = await userService.setOnBoarding()
      await commit(types.UPDATE_USER_ONBOARDING_SUCCESS)
    } catch (e) {
      console.log(e)
    }
  },
  async logout({ commit, dispatch }) {
    try {
      await userService.logout()
      await commit(types.LOGOUT)
    } catch (error) {
      const notification = {
        type: 'error',
        title: 'Error occurs when logging out',
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  },
  async logoutInvalidToken({ commit, dispatch }) {
    await commit(types.LOGOUT)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
