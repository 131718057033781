import apiClient from '@/services'

const getAppConfiguration = () => {
  return apiClient.get('/default-configuration')
}

const getTeams = () => {
  return apiClient.get('/teams')
}

const getAllTeams = () => {
  return apiClient.get('/teams/all')
}

const getTeamWeeks = (teamId) => {
  return apiClient.get(`/team-weeks/${teamId}`)
}

const getTeamWeekDays = () => {
  return apiClient.get('/team-weekdays')
}

const getAllTeamWeeks = (teamId) => {
  return apiClient.get(`/weeks/${teamId}`)
}

const getAllTeamWeekDays = (teamId) => {
  return apiClient.get(`/all-week-days/${teamId}`)
}

const getWorkAllocations = () => {
  return apiClient.get('/work')
}

const getTeamWeekWorkAllocations = (teamId, teamWeekId) => {
  return apiClient.get(`/work-allocations/${teamId}/${teamWeekId}`)
}

const getTeamWeekWorkAllocationsStatus = (teamWeekId) => {
  return apiClient.get(`/work-allocation-status/${teamWeekId}`)
}

const getGameSettings = (teamId) => {
  return apiClient.get(`/game-settings/${teamId}`)
}

const getGameFinancials = (teamId) => {
  return apiClient.get(`/team-game-financials/${teamId}`)
}

const getUsers = () => {
  return apiClient.get('/users')
}

const getPassedScores = () => {
  return apiClient.get('/score-centre/passed')
}

const getProcessedScores = () => {
  return apiClient.get('/score-centre/processed')
}

const getOverallResultsCharts = () => {
  return apiClient.get('/score-centre/overall-results')
}

const getWeeklyResultsCharts = () => {
  return apiClient.get('/score-centre/weekly-results')
}

const getRoundBreakdownTable = (weekId) => {
  return apiClient.get(`/score-centre/weekly-financials/${weekId}`)
}

const getAuditLog = (teamId, lastClaimId) => {
  return apiClient.get(`/simulation-audit-log/${teamId}/${lastClaimId}`)
}

const getAuditLogFilters = () => {
  return apiClient.get(`/audit-log-filters`)
}

const updateTeamWeekday = (data) => {
  return apiClient.post('/team-weekday', data)
}

const updateTeamWeek = (data) => {
  return apiClient.post('/close-team-week', data)
}

const updateWorkAllocations = (data) => {
  return apiClient.post('/work-allocation', data)
}

const updateTeamWeekSettings = (data) => {
  return apiClient.post('/team-week', data)
}

const updateGameSettings = (data) => {
  return apiClient.post('/game-settings', data)
}

const updateGameFinancials = (data) => {
  return apiClient.post('/team-game-financials', data)
}

const addTeam = (data) => {
  return apiClient.post('/team/add', data)
}

const updateTeam = (data) => {
  return apiClient.post('/team/update', data)
}

const deleteTeam = (data) => {
  return apiClient.delete('/team/delete', { data: data })
}

const updateTeamUserFile = (data) => {
  return apiClient.post('/import/users', data)
}

const addUser = (data) => {
  return apiClient.post('/user/add', data)
}

const updateUser = (data) => {
  return apiClient.post('/user/update', data)
}

const deleteUser = (data) => {
  return apiClient.delete('/user/delete', { data: data })
}

const unblockUser = (data) => {
  return apiClient.post('/user/unblock', data)
}

const addApprovalCodesFile = (data) => {
  return apiClient.post('/import/approval-codes', data)
}

const addClaimsFile = (data) => {
  return apiClient.post('/import/claims', data)
}

const resetSimulationData = () => {
  return apiClient.get('/reset-data')
}

const resetUserData = () => {
  return apiClient.get('/reset-data/user')
}

const triggerGame = (id) => {
  return apiClient.post('/control-game-play', id)
}

const rollbackTeamWeekDay = (teamId, fromWeekDayId, toWeekDayId) => {
  return apiClient.post(`/rollback/${teamId}/${fromWeekDayId}/${toWeekDayId}`)
}

export {
  getAppConfiguration,
  getTeams,
  getAllTeams,
  getTeamWeeks,
  getTeamWeekDays,
  getAllTeamWeeks,
  getAllTeamWeekDays,
  getWorkAllocations,
  getTeamWeekWorkAllocations,
  getTeamWeekWorkAllocationsStatus,
  getGameSettings,
  getGameFinancials,
  getUsers,
  getPassedScores,
  getProcessedScores,
  getOverallResultsCharts,
  getWeeklyResultsCharts,
  getRoundBreakdownTable,
  getAuditLog,
  getAuditLogFilters,
  updateTeamWeekday,
  updateTeamWeek,
  updateWorkAllocations,
  updateTeamWeekSettings,
  updateGameSettings,
  updateGameFinancials,
  addTeam,
  updateTeam,
  deleteTeam,
  updateTeamUserFile,
  addUser,
  updateUser,
  deleteUser,
  unblockUser,
  addApprovalCodesFile,
  addClaimsFile,
  resetSimulationData,
  resetUserData,
  triggerGame,
  rollbackTeamWeekDay
}
