<template>
  <div class="running-loader">
    <div class="running d-flex justify-content-center">
      <div class="outer">
        <div class="body">
          <div class="arm behind"></div>
          <div class="arm front"></div>
          <div class="leg behind"></div>
          <div class="leg front"></div>
        </div>
      </div>
    </div>
    <div v-if="loading">
      <span>{{ loadingText }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    loadingText: {
      type: String,
      default: 'Loading...'
    }
  }
}
</script>
