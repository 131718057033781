import { ThemeColors } from '../utils'
import { chartTooltip } from '../utils'
const colors = ThemeColors()

/* dashboard chart data */
export const claimsProcessedChart = {
  data: {
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        label: 'This Week',
        borderColor: colors.themeColor1,
        backgroundColor: colors.themeColor1_10,
        data: [36, 45, 68, 0],
        borderWidth: 1
      }
    ]
  },
  options: {
    legend: {
      display: false
    },
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          gridLines: {
            display: true,
            lineWidth: 1,
            color: 'rgba(0,0,0,0.1)',
            drawBorder: false
          },
          ticks: {
            beginAtZero: true,
            stepSize: 25,
            min: 0,
            max: 100,
            padding: 20
          }
        }
      ],
      xAxes: [
        {
          gridLines: {
            display: false
          }
        }
      ]
    },
    tooltips: chartTooltip
  }
}

// export const accuracyChart = {
//   data: {
//     labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
//     datasets: [
//       {
//         label: '# Passed',
//         borderColor: colors.themeColor1,
//         backgroundColor: colors.themeColor1_10,
//         data: [25, 54, 84, 0],
//         borderWidth: 1
//       },
//       {
//         label: '# Failed',
//         borderColor: colors.themeColor2,
//         backgroundColor: colors.themeColor2_10,
//         data: [10, 25, 20, 0],
//         borderWidth: 1
//       }
//     ]
//   },
//   options: {
//     legend: {
//       position: 'top',
//       labels: {
//         padding: 5,
//         usePointStyle: false,
//         fontSize: 12
//       }
//     },
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       yAxes: [
//         {
//           gridLines: {
//             display: true,
//             lineWidth: 1,
//             color: 'rgba(0,0,0,0.1)',
//             drawBorder: false
//           },
//           ticks: {
//             beginAtZero: true,
//             stepSize: 25,
//             min: 0,
//             max: 100,
//             padding: 20
//           }
//         }
//       ],
//       xAxes: [
//         {
//           gridLines: {
//             display: false
//           }
//         }
//       ]
//     },
//     tooltips: chartTooltip
//   }
// }

// export const profitabilityChart = {
//   data: {
//     labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
//     datasets: [
//       {
//         label: 'Total Revenue',
//         borderColor: colors.themeColor1,
//         backgroundColor: colors.themeColor1_10,
//         data: [200, 360, 1020],
//         borderWidth: 1
//       },
//       {
//         label: 'Total Cost',
//         borderColor: colors.themeColor2,
//         backgroundColor: colors.themeColor2_10,
//         data: [-710, -450, -320],
//         borderWidth: 1
//       },
//       {
//         label: 'Total Profit',
//         type: 'line',
//         borderColor: colors.primaryColor,
//         backgroundColor: colors.themeColor3_10,
//         data: [-510, -90, 900],
//         borderWidth: 2
//       }
//     ]
//   },
//   options: {
//     legend: {
//       position: 'top',
//       labels: {
//         padding: 5,
//         usePointStyle: false,
//         fontSize: 12
//       }
//     },
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       yAxes: [
//         {
//           id: 'A',
//           position: 'left',
//           gridLines: {
//             display: true,
//             lineWidth: 1,
//             color: 'rgba(0,0,0,0.1)',
//             drawBorder: false
//           },
//           ticks: {
//             beginAtZero: true,
//             stepSize: 1000,
//             min: -1000,
//             max: 2000,
//             padding: 20,
//             callback: function(value, index, values) {
//               return '$' + value.toFixed(2)
//             }
//           }
//         },
//         {
//           id: 'B',
//           position: 'right',
//           gridLines: {
//             display: true,
//             lineWidth: 1,
//             color: 'rgba(0,0,0,0.1)',
//             drawBorder: false
//           },
//           ticks: {
//             beginAtZero: true,
//             stepSize: 1000,
//             min: -1000,
//             max: 2000,
//             padding: 20,
//             callback: function(value, index, values) {
//               return '$' + value.toFixed(2)
//             }
//           }
//         }
//       ],
//       xAxes: [
//         {
//           gridLines: {
//             display: false
//           }
//         }
//       ]
//     },
//     tooltips: chartTooltip
//   }
// }

export const weeklyProcessedChart = {
  weekProcessed: {
    labels: ['Mercury', 'Jupiter', 'Saturn', 'Mars'],
    datasets: [
      {
        label: '# Week 1 Processed',
        borderColor: 'themeColor1',
        backgroundColor: 'themeColor1_10',
        borderWidth: 1,
        data: [57, 39, 46, 55]
      },
      {
        label: '# Week 2 Processed',
        borderColor: 'themeColor2',
        backgroundColor: 'themeColor2_10',
        borderWidth: 1,
        data: [65, 72, 60, 70]
      },
      {
        label: '# Week 3 Processed',
        borderColor: 'themeColor3',
        backgroundColor: 'themeColor3_10',
        borderWidth: 1,
        data: [103, 96, 116, 110]
      },
      {
        label: '# Week 4 Processed',
        borderColor: 'themeColor4',
        backgroundColor: 'themeColor4_10',
        borderWidth: 1,
        data: [128, 154, 149, 135]
      }
    ]
  }
}

/* score centre charts */
export const volumeChart = {
  data: {
    labels: ['Week 1 Volume', 'Week 2 Volume', 'Week 3 Volume', 'Week 4 Volume'],
    datasets: [
      {
        label: 'Team 1',
        borderColor: 'themeColor1',
        backgroundColor: 'themeColor1_10',
        borderWidth: 1,
        data: [20, 0, 0, 0]
      },
      {
        label: 'Team 2',
        borderColor: 'themeColor2',
        backgroundColor: 'themeColor2_10',
        borderWidth: 1,
        data: [30, 0, 0, 0]
      },
      {
        label: 'Team 3',
        borderColor: 'themeColor3',
        backgroundColor: 'themeColor3_10',
        borderWidth: 1,
        data: [25, 0, 0, 0]
      },
      {
        label: 'Team 4',
        borderColor: 'themeColor4',
        backgroundColor: 'themeColor4_10',
        borderWidth: 1,
        data: [35, 0, 0, 0]
      }
    ]
  }
}

export const accuracyChart = {
  data: {
    labels: ['Week 1 Accuracy %', 'Week 2 Accuracy %', 'Week 3 Accuracy %', 'Week 4 Accuracy %'],
    datasets: [
      {
        label: 'Team 1',
        borderColor: 'themeColor1',
        backgroundColor: 'themeColor1_10',
        borderWidth: 1,
        data: [30, 0, 0, 0]
      },
      {
        label: 'Team 2',
        borderColor: 'themeColor2',
        backgroundColor: 'themeColor2_10',
        borderWidth: 1,
        data: [20, 0, 0, 0]
      },
      {
        label: 'Team 3',
        borderColor: 'themeColor3',
        backgroundColor: 'themeColor3_10',
        borderWidth: 1,
        data: [50, 0, 0, 0]
      },
      {
        label: 'Team 4',
        borderColor: 'themeColor4',
        backgroundColor: 'themeColor4_10',
        borderWidth: 1,
        data: [45, 0, 0, 0]
      }
    ]
  }
}

export const weeklyAccuracyChart = {
  data: {
    title: 'Accuracy',
    labels: ['Week 1', 'Week 2', 'Week 3', 'Week 4'],
    datasets: [
      {
        formatter: 'percentage',
        label: 'Team 1',
        // type: 'lineWithShadow',
        // yAxisID: 'A',
        data: [10, 15, 20, 25]
      },
      {
        formatter: 'percentage',
        label: 'Team 2',
        // type: 'lineWithShadow',
        // yAxisID: 'A',
        data: [25, 30, 65, 70]
      },
      {
        formatter: 'percentage',
        label: 'Team 3',
        // type: 'lineWithShadow',
        // yAxisID: 'A',
        data: [5, 33, 44, 55]
      },
      {
        formatter: 'percentage',
        label: 'Team 4',
        // type: 'lineWithShadow',
        // yAxisID: 'A',
        data: [23, 25, 65, 73]
      }
    ]
  }
}
