import { claimsProcessedChart, accuracyChart, profitabilityChart, volumeChart } from '@/data/charts'
import * as types from '../mutation-types'
import { ThemeColors } from '@/utils'
import { chartTooltip } from '@/utils'
import { mapValues } from 'lodash'
const colors = ThemeColors()
import { dashboardService } from '@/services'

const state = {
  vmbCharts: null
}

const getters = {
  vmbCharts: state => state.vmbCharts,
  claimsProcessedChart: state => {
    let claimsProcessedData = state.vmbCharts.claimsProcessed
    return {
      labels: claimsProcessedData.labels,
      datasets: [
        {
          label: 'This Week',
          borderColor: colors.themeColor1,
          backgroundColor: colors.themeColor1_10,
          data: claimsProcessedData.data,
          borderWidth: 1
        }
      ]
    }
  },
  accuracyChart: state => {
    let accuracyData = state.vmbCharts.accuracy
    let chartColors = [
      {
        borderColor: colors.themeColor1,
        backgroundColor: colors.themeColor1_10,
        borderWidth: 1
      },
      {
        borderColor: colors.themeColor2,
        backgroundColor: colors.themeColor2_10,
        borderWidth: 1
      }
    ]
    return {
      labels: accuracyData.labels,
      datasets: _.merge(accuracyData.datasets, chartColors)
    }
  },
  profitabilityChart: state => {
    let profitabilityData = state.vmbCharts.profitability
    let chartProperties = [
      {
        borderColor: colors.themeColor1,
        backgroundColor: colors.themeColor1_10,
        borderWidth: 1
      },
      {
        borderColor: colors.themeColor2,
        backgroundColor: colors.themeColor2_10,
        borderWidth: 1
      },
      {
        borderColor: colors.primaryColor,
        backgroundColor: colors.themeColor3_10,
        borderWidth: 1
      }
    ]
    return {
      labels: profitabilityData.labels,
      datasets: _.merge(profitabilityData.datasets, chartProperties)
    }
  }
}

const mutations = {
  [types.FETCH_VMBCHARTSDATA_SUCCESS](state, payload) {
    state.vmbCharts = payload
  },
  [types.FETCH_VMBCHARTSDATA_FAILURE](state) {
    state.vmbCharts = null
  }
}

const actions = {
  async fetchVmbChartData({ commit, dispatch }) {
    try {
      const { data } = await dashboardService.getVmbChartData()
      await commit(types.FETCH_VMBCHARTSDATA_SUCCESS, data.data)
    } catch (error) {
      const notification = {
        type: 'error',
        title: `Error occurs when fetching VMB chart data`,
        message: error.response.data.message
      }
      await dispatch('notification/addNotification', notification, { root: true })
    }
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}
