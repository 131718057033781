import Vue from 'vue'
import VueRouter from 'vue-router'
import AuthRequired from './utils/AuthRequired'
import i18n from '@/i18n'
import { role } from '@/constants/config'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: () => import(/* webpackChunkName: "app" */ './views/app'),
    meta: { authorize: [] },
    beforeEnter: AuthRequired,
    children: [
      {
        path: '',
        name: 'home',
        component: () => import(/* webpackChunkName: "Home" */ './views/app/Home'),
        meta: { authorize: [] }
      },
      {
        path: '/rules',
        name: 'rules',
        component: () => import(/* webpackChunkName: "Rules" */ './views/app/Rules'),
        meta: { authorize: [] }
      },
      {
        path: '/policies',
        name: 'policies',
        component: () => import(/* webpackChunkName: "Policies" */ './views/app/Policies'),
        meta: { authorize: [] }
      },
      {
        path: '/systems',
        name: 'systems',
        component: () => import(/* webpackChunkName: "Systems" */ './views/app/Systems'),
        meta: { authorize: [] }
      },
      {
        path: '/lodgements',
        name: 'lodgements',
        component: () => import(/* webpackChunkName: "Lodgements" */ './views/app/Lodgements'),
        meta: { authorize: [] }
      },
      {
        path: 'vmb',
        component: () => import('./views/app/dashboard'),
        children: [
          {
            path: '/vmb',
            meta: { id: 99, title: 'Dashboard', authorize: [] },
            component: () =>
              import(/* webpackChunkName: "dashboards" */ './views/app/dashboard/Dashboards')
          },
          {
            name: 'what_went_well',
            path: 'what-went-well',
            meta: { id: 3, title: i18n.t('menu.vmb-menu.what-went-well'), authorize: [] },
            component: () =>
              import(
                /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
              )
          },
          {
            name: 'communication',
            path: 'communication',
            meta: { id: 4, title: i18n.t('menu.vmb-menu.communications'), authorize: [] },
            component: () =>
              import(
                /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
              )
          },
          {
            name: 'feedback',
            path: 'feedback',
            meta: { id: 5, title: i18n.t('menu.vmb-menu.feedback'), authorize: [] },
            component: () =>
              import(
                /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
              )
          },
          {
            path: 'performance',
            meta: { id: 6, title: i18n.t('menu.vmb-menu.performance'), authorize: [] },
            component: () =>
              import(/* webpackChunkName: "dashboards" */ './views/app/dashboard/Performance')
          },
          {
            name: 'problems',
            path: 'problems',
            meta: { id: 1, title: i18n.t('menu.vmb-menu.problems'), authorize: [] },
            component: () =>
              import(
                /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
              )
          },
          {
            name: 'actions',
            path: 'actions',
            meta: { id: 2, title: i18n.t('menu.vmb-menu.actions'), authorize: [] },
            component: () =>
              import(
                /* webpackChunkName: "dashboards" */ './views/app/dashboard/SurveyObservationDetails'
              )
          },
          {
            path: 'people',
            meta: { id: 7, title: i18n.t('menu.vmb-menu.people'), authorize: [] },
            component: () =>
              import(/* webpackChunkName: "dashboards" */ './views/app/dashboard/People')
          }
        ]
      },
      {
        path: 'admin',
        component: () => import(/* webpackChunkName: "admin" */ './views/app/admin'),
        meta: { authorize: [role.admin, role.leader] },
        redirect: '/admin/game-control',
        children: [
          {
            path: 'game-control',
            component: () =>
              import(/* webpackChunkName: "scores" */ './views/app/admin/GameControl'),
            meta: { id: 1, title: 'Game Control', authorize: [role.admin, role.leader] }
          },
          {
            path: 'scores',
            meta: { id: 2, title: 'Scores', authorize: [role.admin, role.leader] },
            component: () =>
              import(/* webpackChunkName: "scores" */ './views/app/admin/ScoreCentre')
          },
          {
            path: 'audit-log',
            meta: { id: 3, title: 'Audit Log', authorize: [role.admin, role.leader] },

            component: () =>
              import(/* webpackChunkName: "audit-log" */ './views/app/admin/AuditLog')
          },
          {
            path: 'management',
            component: () => import('./views/app/admin/management'),
            meta: { id: 4, title: 'Management', authorize: [role.admin, role.leader] },
            redirect: '/admin/management/teams',
            children: [
              {
                path: 'users',
                component: () =>
                  import(/* webpackChunkName: "admin" */ './views/app/admin/management/Users'),
                meta: { id: 4, authorize: [role.admin, role.leader] }
              },
              {
                path: 'roles',
                component: () =>
                  import(/* webpackChunkName: "users" */ './views/app/admin/management/Management'),
                meta: { id: 4, authorize: [role.admin] }
              },
              {
                path: 'teams',
                component: () =>
                  import(/* webpackChunkName: "teams" */ './views/app/admin/management/Teams'),
                meta: { id: 4, authorize: [role.admin, role.leader] }
              },
              {
                path: 'approval-codes',
                component: () =>
                  import(
                    /* webpackChunkName: "approval-codes" */ './views/app/admin/management/ApprovalCodes'
                  ),
                meta: { id: 4, authorize: [role.admin] }
              },
              {
                path: 'claim-master',
                component: () =>
                  import(
                    /* webpackChunkName: "claim-master" */ './views/app/admin/management/ClaimMaster'
                  ),
                meta: { id: 4, authorize: [role.admin] }
              }
            ]
          }
        ]
      }
    ]
  },
  {
    path: '/error',
    component: () => import(/* webpackChunkName: "error" */ './views/Error')
  },
  {
    path: '/work',
    beforeEnter: AuthRequired,
    meta: { authorize: [role.admin, role.leader, role.user] },
    component: () => import(/* webpackChunkName: "work" */ './views/work'),
    children: [
      {
        path: 'allocations',
        component: () => import(/* webpackChunkName: "work" */ './views/work/Allocations'),
        meta: { authorize: [role.admin, role.leader, role.user] }
      }
    ]
  },
  {
    path: '/user',
    component: () => import(/* webpackChunkName: "user" */ './views/user'),
    redirect: '/user/login',
    children: [
      {
        path: 'login',
        component: () => import(/* webpackChunkName: "user" */ './views/user/Login')
      },
      {
        path: 'register',
        component: () => import(/* webpackChunkName: "user" */ './views/user/Register')
      },
      {
        path: 'forgot-password',
        component: () => import(/* webpackChunkName: "user" */ './views/user/ForgotPassword')
      },
      {
        path: 'reset-password',
        component: () => import(/* webpackChunkName: "user" */ './views/user/ResetPassword')
      }
    ]
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "error" */ './views/Error')
  }
]

const router = new VueRouter({
  linkActiveClass: 'active',
  routes,
  mode: 'history'
})

export default router
